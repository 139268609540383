import { Cookies } from 'react-cookie';
const cookies = new Cookies();

/**
 * Set the token
 *
 * @param token string
 */
function set(token)
{
    cookies.set('token', token, { path: '/', secure: process.env.REACT_APP_USE_HTTPS==='1', sameSite: 'strict'});
}

/**
 * Retrieve the token value from a cookie
 *
 * @return String
 */
function get()
{
    return cookies.get('token');
}

/**
 * Remove the token from cookie content
 */
function remove()
{
    cookies.remove('token', { path: '/'});
}

export default { set, get, remove };
