import type {ReduxActionType} from "../../flow/ReduxActionType";

const validateAction = (state, callback, ...args) => {
    try {
        return Boolean(callback(state, ...args));
    } catch (e) {
        console.log('Policy middleware:', e.message);
        return false;
    }
};

const findBinding = (state, bindingName: ?string, bindingValue: ?string) => {
    if (!bindingName || !bindingName.endsWith('_id')) {
        return null;
    }

    const singularName = bindingName.replace(/_id$/, '');

    const pluralName = `${singularName}s`;

    return state[pluralName].find((entity) => entity.id === parseInt(bindingValue));
};

const createPolicyMiddleware = () => ({dispatch, getState}) => (next) => (action: ReduxActionType) => {
    const {type, payload} = action;

    switch (type) {
        case 'POLICY_VALIDATE_USER_CAN':
        case 'POLICY_VALIDATE_USER_CANNOT':
            const allowed = validateAction(getState().App, payload.action, ...payload.args);
            return type === 'POLICY_VALIDATE_USER_CAN' ? allowed : !allowed;

        case 'POLICY_RESOLVE_BINDING':
            return findBinding(getState().App, payload.name, payload.value);
    }

    return next(action);
};

export default createPolicyMiddleware;
