// @flow

import type {ReduxActionType} from "../../flow/ReduxActionType";


/**
 * Change input content
 * @type: Action
 */
export const linkChangeInput = (name: string, value: all, type: string): ReduxActionType => ({
    type: 'LINK_CHANGE_INPUT',
    payload: { name, value, type },
});

/**
 * Save a link
 * @type: Action
 */
export const linkSave = (domain_id: number, quit: boolean): ReduxActionType => ({
    type: 'LINK_SAVE',
    payload: {domain_id, quit},
});

/**
 * Link has been saved
 * @type: Event
 */
export const linkSaved = (link_id: number): ReduxActionType => ({
    type: 'LINK_SAVED',
    payload: link_id,
});

/**
 * Link has been saved and quit page
 * @type: Event
 */
export const linkSavedAndQuit = (link_id: number): ReduxActionType => ({
    type: 'LINK_SAVED_AND_QUIT',
    payload: link_id,
});


/**
 * Link form has been close
 * @type: Event
 */
export const linkClosed = (): ReduxActionType => ({
    type: 'LINK_CLOSED',
    payload: null,
});

/**
 * Load link
 * @type: Action
 */
export const linkLoad = (domain_id: number, id: number): ReduxActionType => ({
    type: 'LINK_LOAD',
    payload: { domain_id, id },
});

/**
 * Link loaded
 * @type: Event
 */
export const linkLoaded = (link: {}): ReduxActionType => ({
    type: 'LINK_LOADED',
    payload: link ,
});


/**
 * Link loaded
 * @type: Event
 */
export const linkTranslationDelete = (domain_id: number, id: number): ReduxActionType => ({
    type: 'LINK_TRANSLATION_DELETE',
    payload: {domain_id, id} ,
});

/**
 * Link loaded
 * @type: Event
 */
export const linkTranslationDeleted = (domain_id: number, id: number): ReduxActionType => ({
    type: 'LINK_TRANSLATION_DELETED',
    payload: {domain_id, id},
});

/**
 * Link request failed
 * @type: Event
 */
export const linkRequestFailed = (): ReduxActionType => ({
    type: 'LINK_REQUEST_FAILED',
    payload: null,
});
