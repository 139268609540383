// @flow

import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load translation
 * @type: Action
 */
export const translationLoad = (domain_id: number, id: number): ReduxActionType => ({
    type: 'TRANSLATION_LOAD',
    payload: { domain_id, id },
});

/**
 * Translation loaded
 * @type: Event
 */
export const translationLoaded = (translation: {}): ReduxActionType => ({
    type: 'TRANSLATION_LOADED',
    payload: translation ,
});

/**
 * Change input content
 * @type: Action
 */
export const translationChangeInput = (name: string, value: all, type: string): ReduxActionType => ({
    type: 'TRANSLATION_CHANGE_INPUT',
    payload: { name, value, type },
});

/**
 * Click on insertion
 * @type: Action
 */
export const translationClickInsertion = (type: string, id: number): ReduxActionType => ({
    type: 'TRANSLATION_CLICK_INSERTION',
    payload: { type, id },
});

/**
 * Save a translation
 * @type: Action
 */
export const translationSave = (domain_id: number, quit: boolean): ReduxActionType => ({
    type: 'TRANSLATION_SAVE',
    payload: {domain_id, quit},
});

/**
 * Translation has been saved
 * @type: Event
 */
export const translationSaved = (translation_id: number): ReduxActionType => ({
    type: 'TRANSLATION_SAVED',
    payload: translation_id,
});

/**
 * Translation has been saved
 * @type: Event
 */
export const translationSavedAndQuit = (translation_id: number): ReduxActionType => ({
    type: 'TRANSLATION_SAVED_AND_QUIT',
    payload: translation_id,
});

/**
 * Translation form has been close
 * @type: Event
 */
export const translationClosed = (): ReduxActionType => ({
    type: 'TRANSLATION_CLOSED',
    payload: null,
});

/**
 * Delete a translation
 * @type: Action
 */
export const translationDelete = (domain_id: number, id: number): ReduxActionType => ({
    type: 'TRANSLATION_DELETE',
    payload: {domain_id, id},
});

/**
 * Translation deleted
 * @type: Event
 */
export const translationDeleted = (translation_id: number): ReduxActionType => ({
    type: 'TRANSLATION_DELETED',
    payload: translation_id,
});

/**
 * Request failed
 * @type: Event
 */
export const translationRequestFailed = (): ReduxActionType => ({
    type: 'TRANSLATION_REQUEST_FAILED',
    payload: null,
});
