// @flow
import { select, all, call, fork, put, takeEvery, take } from 'redux-saga/effects';

import {
    billingLoaded,
    appMessageThrow,
    appRedirect,
    billingRequestFailed,
} from '../actions';
import {fetchJSON} from "../../helpers/api";
import {pick} from "react-bootstrap-typeahead/es/utils";

/**
 * Watch load billing request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchBillingLoad(): any {
    yield takeEvery('BILLING_LOAD', function* ({payload: account_id}) {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, `/accounts/${account_id}/billing`, options);
            yield put(billingLoaded(response));
        } catch (error) {
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else if (error.status_code === 422) {
                yield put(appMessageThrow(error.message, 'warning'));
                yield put(billingRequestFailed());
                yield put(appRedirect('/'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch user want to save an account
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchBillingSave(): any {
    yield takeEvery('BILLING_SAVE', function* ({payload: account_id}) {
        try {
            const state = yield select();

            const account = JSON.parse(JSON.stringify(state.Billing.billing));

            const data = pick(account, ['name', 'phone', 'email', 'address', 'invoice_settings']);

            const options = {
                body: JSON.stringify(data),
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, `/accounts/${account_id}/billing`, options);
            //todo: handle success=false response

            yield put(appRedirect('/'));

        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else if (error.status_code === 422) {
                yield put(appMessageThrow(error.message, 'warning'));
                yield put(billingRequestFailed());
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

function* BillingSaga(): any {
    yield all([fork(watchBillingLoad), fork(watchBillingSave)]);
}

export default BillingSaga;
