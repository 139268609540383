// @flow


import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load urls
 */
export const urlsLoad = (domain_id): ReduxActionType => ({
    type: 'URLS_LOAD',
    payload: {domain_id},
});

/**
 * Urls loaded
 */
export const urlsLoaded = (urls: [], pagination: {}): ReduxActionType => ({
    type: 'URLS_LOADED',
    payload: {urls, pagination},
});

/**
 * Change input content
 * @type: Action
 */
export const urlsChangeInput = (name: string, value: all, type: string, domain_id: number): ReduxActionType => ({
    type: 'URLS_CHANGE_INPUT',
    payload: { domain_id, name, value },
});
