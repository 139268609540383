// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    invoicesLoaded,
    invoicesPayStarted,
    appRedirect
} from '../actions';
import {fetchJSON} from "../../helpers/api";

/**
 * Watch load invoices request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchInvoicesLoad(): any {
    yield takeEvery('INVOICES_LOAD', function* ({payload: account_id}) {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, `/accounts/${account_id}/invoices`, options);
            yield put(invoicesLoaded(response));
        } catch (error) {
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch payment manual of invoice request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchInvoicesPayInit(): any {
    yield takeEvery('INVOICES_PAY_INIT', function* ({payload: {account_id, invoice_id}}) {
        try {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, `/accounts/${account_id}/payments/manual/${invoice_id}`, options);

            // Redirect to invoice page
            window.location.href = response.url;

            yield put(invoicesPayStarted(invoice_id));
        } catch (error) {
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

function* InvoicesSaga(): any {
    yield all([fork(watchInvoicesLoad), fork(watchInvoicesPayInit)]);
}

export default InvoicesSaga;
