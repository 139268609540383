// @flow
import { all } from 'redux-saga/effects';
import appSaga from './app/saga';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import RulesSaga from './rules/saga';
import RuleSaga from './rule/saga';
import DomainSaga from './domain/saga';
import TranslationsSaga from './translations/saga';
import TranslationSaga from './translation/saga';
import UrlsSaga from './urls/saga';
import UrlSaga from './url/saga';
import StatisticsSaga from './statistics/saga';
import AccountSaga from "./account/saga";
import InvoicesSaga from "./invoices/saga";
import BillingSaga from "./billing/saga";
import SubscriptionsSaga from "./subscriptions/saga";
import AccountBillingsSaga from './accountBillings/saga';
import AccountMembersSaga from "./accountMembers/saga";
import SearchTranslationsSaga from "./searchTranslations/saga";
import LinksSaga from "./links/saga";
import LinkSaga from "./link/saga";

export default function* rootSaga(getState: any): any {
    yield all([
        appSaga(),
        authSaga(),
        layoutSaga(),
        appMenuSaga(),
        RulesSaga(),
        RuleSaga(),
        DomainSaga(),
        TranslationsSaga(),
        TranslationSaga(),
        SearchTranslationsSaga(),
        UrlsSaga(),
        UrlSaga(),
        StatisticsSaga(),
        AccountSaga(),
        InvoicesSaga(),
        BillingSaga(),
        SubscriptionsSaga(),
        AccountBillingsSaga(),
        AccountMembersSaga(),
        LinksSaga(),
        LinkSaga(),
    ]);
}
