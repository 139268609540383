// @flow
import type {ReduxActionType} from "../../flow/ReduxActionType";
import type {UserType} from "../../flow/UserType";

/**
 * Load account members
 * @type: Action
 */
export const accountMembersLoad = (id: number): ReduxActionType => ({
    type: 'ACCOUNT_MEMBERS_LOAD',
    payload: id,
});

/**
 * Account members loaded
 * @type: Event
 */
export const accountMembersLoaded = (members: UserType[]): ReduxActionType => ({
    type: 'ACCOUNT_MEMBERS_LOADED',
    payload: members,
});

/**
 * Save account member
 * @type: Event
 */
export const accountMemberSave = (id: number, member: UserType): ReduxActionType => ({
    type: 'ACCOUNT_MEMBER_SAVE',
    payload: {account_id: id, member},
});

/**
 * Save account member
 * @type: Event
 */
export const accountMemberSaved = (member: UserType): ReduxActionType => ({
    type: 'ACCOUNT_MEMBER_SAVED',
    payload: member,
});

/**
 * Save account member
 * @type: Event
 */
export const accountMemberDetach = (id: number, member: UserType): ReduxActionType => ({
    type: 'ACCOUNT_MEMBER_DETACH',
    payload: {account_id: id, member},
});

/**
 * Save account member
 * @type: Event
 */
export const accountMemberDetached = (member: UserType): ReduxActionType => ({
    type: 'ACCOUNT_MEMBER_DETACHED',
    payload: member,
});


/**
 * Request failed
 * @type: Event
 */
export const accountMembersRequestFailed = (): ReduxActionType => ({
    type: 'ACCOUNT_MEMBERS_REQUEST_FAILED',
    payload: null,
});

/**
 * Resend invite to account member
 * @type: Event
 */
export const accountMemberInviteResend = (id: number, member: UserType): ReduxActionType => ({
    type: 'ACCOUNT_MEMBER_INVITE_RESEND',
    payload: {account_id: id, member},
});

/**
 * Resend invite to account member
 * @type: Event
 */
export const accountMemberInviteSent = (id: number): ReduxActionType => ({
    type: 'ACCOUNT_MEMBER_INVITE_SENT',
    payload: {id},
});
