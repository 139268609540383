// @flow
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {route} from "../helpers/common";
import HelpLinkIcon from "./HelpLinkIcon";
import PropTypes from 'prop-types';
import TooltipItem from "./TooltipItem";
import PaypalBanner from './PaypalBanner';

type PageTitleProps = {
    breadCrumbItems: PropTypes.object,
    domain_id: number,
    title: string,
    help_link?: string,
    paypal_banner?: {
        account_id: number,
        fallback: boolean,
    },
};

/**
 * PageTitle
 */
const PageTitle = (props: PageTitleProps) => {
    const [showBanner, setShowBanner] = useState(true);

    return (
        <Row>
            <Col>
                {props.paypal_banner && !props.paypal_banner.fallback && showBanner && (
                    <div className="mt-3 w-100" style={{marginBottom: ''}}>
                        <PaypalBanner accountId={props.paypal_banner.account_id} onDismiss={() => setShowBanner(false)} />
                    </div>
                )}
                <div className="page-title-box">
                    <div className="page-title-right">
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to={(props.domain_id ? `/domain/${props.domain_id}/dashboard` : '/')}>Linguise</Link>
                            </BreadcrumbItem>
                            {props.breadCrumbItems.map((item, index) => {
                                return item.active ? (
                                    <BreadcrumbItem active key={index}>
                                        {item.label}
                                    </BreadcrumbItem>
                                ) : (
                                    <BreadcrumbItem key={index}>
                                        <Link to={item.path}>{item.label}</Link>
                                    </BreadcrumbItem>
                                );
                            })}
                        </Breadcrumb>
                    </div>
                    <h4 className="page-title">
                        {props.title}
                        {props.help_link && (
                            <>
                                <HelpLinkIcon href={props.help_link} title={props.title} id={props.id} />
                                <TooltipItem content={props.title} target={props.id} placement="top" />
                            </>
                        )}
                    </h4>
                </div>
            </Col>
        </Row>
    );
};

export default PageTitle;
