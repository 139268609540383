// @flow
import { select, all, call, fork, put, takeEvery, take } from 'redux-saga/effects';

import {
    accountLoaded,
    appMessageThrow,
    appRedirect,
    accountRequestFailed,
    accountAdded, accountsLoaded,
} from '../actions';
import {fetchJSON} from "../../helpers/api";
import {pick} from "react-bootstrap-typeahead/es/utils";
import {appUserUpdated} from "../app/actions";
import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Watch load account request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAccountLoad(): any {
    yield takeEvery('ACCOUNT_LOAD', function*() {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, '/user/profile', options);
            yield put(accountLoaded(response));
        } catch (error) {
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else if (error.status_code === 422) {
                yield put(appMessageThrow(error.message, 'warning'));
                yield put(accountRequestFailed());
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch user want to save an account
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAccountSave(): any {
    yield takeEvery('ACCOUNT_SAVE', function*() {
        try {
            const state = yield select();

            const account = JSON.parse(JSON.stringify(state.Account.account));

            const data = pick(account, ['name', 'password', 'emailPreferences']);

            const options = {
                body: JSON.stringify(data),
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, '/user/profile', options);
            //todo: handle success=false response

            yield put(appUserUpdated(response));
            yield put(appRedirect('/'));

        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

function* handle(error) {
    switch (error.status_code) {
        case 404:
            yield put(appRedirect('/error-404'));
            break;

        case 403:
        case 422:
            yield put(appMessageThrow(error.message, 'warning'));
            // yield put(accountMembersRequestFailed()); // todo
            break;

        default:
            yield put(appRedirect('/error-500'));
            break;
    }
}

// todo THIS IS ACTUAL ACCOUNT MANAGEMENT INSTEAD OF PROFILE MANAGEMENT ABOVE!
/**
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAccountsLoad(): any {
    yield takeEvery('ACCOUNTS_LOAD', function* ({payload: name}: ReduxActionType) {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, '/accounts', options);

            yield put(accountsLoaded(response));
        } catch (error) {
            handle(error);
        }
    });
}

/**
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAccountAdd(): any {
    yield takeEvery('ACCOUNT_ADD', function* ({payload: name}: ReduxActionType) {
        try {
            const options = {
                body: JSON.stringify({name}),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, '/accounts', options);

            yield put(accountAdded(response));
        } catch (error) {
            handle(error);
        }
    });
}

function* AccountSaga(): any {
    yield all([fork(watchAccountLoad), fork(watchAccountSave), fork(watchAccountsLoad), fork(watchAccountAdd)]);
}

export default AccountSaga;
