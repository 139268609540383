import type {AccountType} from "../flow/AccountType";
import type {DomainType} from "../flow/DomainType";
import * as account from './account';
import * as domain from './domain';

export type PolicyStateType = {
    user: {
        id: number,
        name: string,
        email: string,
        avatar: string,
        can_add_accounts: boolean,
    },
    accounts: AccountType[],
    domains: DomainType[],
    roles: {},
};

const policies = {
    account,
    domain,
};

export default policies;
