import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import english from './locales/en/linguise.json';
import french from './locales/fr/linguise.json';
import spanish from './locales/es/linguise.json';
import german from './locales/de/linguise.json';
import italian from './locales/it/linguise.json';
import russian from './locales/ru/linguise.json';
import japanese from './locales/ja/linguise.json';
import vietnamese from './locales/vi/linguise.json';
import dutch from './locales/nl/linguise.json';

import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        ns: 'linguise',
        defaultNS: 'linguise',

        fallbackLng: 'en',
        debug: process.env.node_ENV === 'development',
        useSuspense: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        simplifyPluralSuffix: true,
        pluralSeparator: '@',
        resources: {
            'en': {linguise: english},
            'fr-FR': {linguise: french},
            'es-ES': {linguise: spanish},
            'de-DE': {linguise: german},
            'it-IT': {linguise: italian},
            'ru-RU': {linguise: russian},
            'ja-JA': {linguise: japanese},
            'vn-VN': {linguise: vietnamese},
            'nl-NL': {linguise: dutch},
        },

        detection: {
            lookupCookie: 'lang'
        }
    });


export default i18n;
