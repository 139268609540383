// @flow


import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load statistics
 */
export const statisticsLoad = (domain_id): ReduxActionType => ({
    type: 'STATISTICS_LOAD',
    payload: {domain_id},
});

/**
 * Statistics loaded
 */
export const statisticsLoaded = (statistics: [], pagination: {}): ReduxActionType => ({
    type: 'STATISTICS_LOADED',
    payload: {statistics, pagination},
});

/**
 * Load statistics charts (languages)
 */
export const statisticsLanguagesLoad = (domain_id): ReduxActionType => ({
    type: 'STATISTICS_LANGUAGES_LOAD',
    payload: {domain_id},
});

/**
 * Statistics charts loaded (languages)
 */
export const statisticsLanguagesLoaded = (data: {}): ReduxActionType => ({
    type: 'STATISTICS_LANGUAGES_LOADED',
    payload: data,
});

/**
 * Load statistics charts (visits)
 */
export const statisticsVisitsLoad = (domain_id): ReduxActionType => ({
    type: 'STATISTICS_VISITS_LOAD',
    payload: {domain_id},
});

/**
 * Statistics charts loaded (visits)
 */
export const statisticsVisitsLoaded = (data: {}): ReduxActionType => ({
    type: 'STATISTICS_VISITS_LOADED',
    payload: data,
});

/**
 * Load statistics charts (pages)
 */
export const statisticsPagesLoad = (domain_id): ReduxActionType => ({
    type: 'STATISTICS_PAGES_LOAD',
    payload: {domain_id},
});

/**
 * Statistics charts loaded (pages)
 */
export const statisticsPagesLoaded = (data: {}): ReduxActionType => ({
    type: 'STATISTICS_PAGES_LOADED',
    payload: data,
});

/**
 * Load statistics charts (usages)
 */
export const statisticsUsagesLoad = (domain_id): ReduxActionType => ({
    type: 'STATISTICS_USAGES_LOAD',
    payload: {domain_id},
});

/**
 * Statistics charts loaded (usages)
 */
export const statisticsUsagesLoaded = (data: {}): ReduxActionType => ({
    type: 'STATISTICS_USAGES_LOADED',
    payload: data,
});

/**
 * Change input content
 * @type: Action
 */
export const statisticsChangeInput = (name: string, value: all, type: string, domain_id: number): ReduxActionType => ({
    type: 'STATISTICS_CHANGE_INPUT',
    payload: { domain_id, name, value },
});
