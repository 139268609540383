// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    accountBillingsLoaded,
    accountBillingsRequestFailed,
    accountBillingsBindFinish,
    accountBillingUpdateSingle,
    accountBillingsDefaultSet,
    accountBillingsFallbackSet,
    accountBillingsDeleted,
    appBillingsInfoUpdated,
    appMessageThrow,
    appRedirect,
} from '../actions';
import {fetchJSON} from "../../helpers/api";

function* handle(error) {
    switch (error.status_code) {
        case 404:
            yield put(appRedirect('/error-404'));
            break;

        case 403:
        case 422:
            yield put(appMessageThrow(error.message, 'warning'));
            yield put(accountBillingsRequestFailed());
            break;

        case 503: // Service Unavailable
            yield put(appMessageThrow(error.message, 'warning'));
            yield put(accountBillingsRequestFailed());
            break;

        default:
            yield put(appRedirect('/error-500'));
            break;
    }
}

/**
 * Watch load account billing request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAccountBillingsLoad(): any {
    yield takeEvery('ACCOUNT_BILLINGS_LOAD', function*({payload: account_id}) {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, `/accounts/${account_id}/payments`, options);
            yield put(accountBillingsLoaded({accountId: account_id, paymentMethods: response.billings, paypalPromoUsed: response.paypal_promo}));
        } catch (error) {
            yield handle(error);
        }
    });
}

/**
 * Watch account billings paypal bind request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAccountBillingsPaypalBind(): any {
    yield takeEvery('ACCOUNT_BILLINGS_PAYPAL_BIND', function*({payload: {accountId}}) {
        try {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, `/accounts/${accountId}/payments/paypal`, options);

            if (response.url) {
                // In 100ms, redirect to the URL provided by the server
                setTimeout(() => {
                    window.location.href = response.url;
                }, 100);
            } else if (response.error) {
                yield put(appMessageThrow(response.error, 'warning'));                
            } else {
                yield put(appMessageThrow('An unknown error has occurred while processing your request', 'warning'));
            }

            yield put(accountBillingsBindFinish());
        } catch (error) {
            yield handle(error);
        }
    });
}

/**
 * Watch account billings stripe bind request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAccountBillingsStripeBind(): any {
    yield takeEvery('ACCOUNT_BILLINGS_STRIPE_BIND', function*({payload: {accountId}}) {
        try {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, `/accounts/${accountId}/payments/stripe`, options);

            // In 100ms, redirect to the URL provided by the server
            setTimeout(() => {
                window.location.href = response.url;
            }, 100);

            yield put(accountBillingsBindFinish());
        } catch (error) {
            yield handle(error);
        }
    });
}

/**
 * Watch account billing set default request
 */
export function* watchAccountBillingsSetFallback(): any {
    yield takeEvery('ACCOUNT_BILLINGS_SET_FALLBACK', function*({payload: {accountId, paymentId}}) {
        try {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, `/accounts/${accountId}/payments/${paymentId}/fallback`, options);

            yield put(accountBillingUpdateSingle(response));
            yield put(appBillingsInfoUpdated({id: response.id, fallback: response.fallback, account_id: response.account_id}));
            yield put(accountBillingsFallbackSet());
        } catch (error) {
            yield handle(error);
        }
    });
}

/**
 * Watch account billing method delete request
 */
export function* watchAccountBillingsDeleteMethod(): any {
    yield takeEvery('ACCOUNT_BILLINGS_DELETE', function*({payload: {accountId, paymentId}}) {
        try {
            const options = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            };

            yield call(fetchJSON, `/accounts/${accountId}/payments/${paymentId}`, options);
            yield put(accountBillingsDeleted(paymentId));
        } catch (error) {
            yield handle(error);
        }
    });
}

function* AccountBillingsSaga(): any {
    yield all(
        [
            fork(watchAccountBillingsLoad),
            fork(watchAccountBillingsPaypalBind),
            fork(watchAccountBillingsStripeBind),
            fork(watchAccountBillingsSetFallback),
            fork(watchAccountBillingsDeleteMethod),
        ]
    );
}

export default AccountBillingsSaga;
