// @flow

import update from 'immutability-helper';
import type {ReduxActionType} from "../../flow/ReduxActionType";

const INITIAL_STATE = {
    plans: [],
    saving: false,
    paypalPlan: null,
    loading: true,
};

type State = { type?: string };

const Subscriptions = (state: State = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'SUBSCRIPTION_PLANS_LOAD':
            return {...INITIAL_STATE};

        case 'SUBSCRIPTION_PLANS_LOADED':
            return {
                ...state,
                plans: action.payload,
                loading: false,
            };

        case 'DOMAIN_SUBSCRIPTION_UPDATE':
            return {...state, saving: true};

        case 'SUBSCRIPTION_PLANS_REQUEST_FAILED':
            return {...state, saving: false};

        case 'SUBSCRIPTION_PAYPAL_INITIATE': {
            return {
                ...state,
                paypalPlan: action.payload,
                saving: false,
            }
        }

        case 'SUBSCRIPTION_PAYPAL_FINISH': {
            return {
                ...state,
                paypalPlan: null,
            }
        }

        default:
            return state;
    }
};

export default Subscriptions;
