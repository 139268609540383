// @flow
import type {ReduxActionType} from "../../flow/ReduxActionType";
import type {UserType} from "../../flow/UserType";

const INITIAL_STATE = {
    members: [],
    availableRoles: [],
    inviteStatuses: {},
    invitesResent: [],
    loading: true,
};

const AccountMembers = (state = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'ACCOUNT_MEMBERS_LOAD':
            return {...INITIAL_STATE};

        case 'ACCOUNT_MEMBERS_LOADED':
            return {
                ...state,
                ...action.payload,
                loading: false,
            };

        case 'ACCOUNT_MEMBER_SAVE':
            return {
                ...state,
                loading: true,
            };

        case 'ACCOUNT_MEMBER_SAVED':
            const memberExists = state.members.find((member: UserType) => member.id === action.payload.id);

            const members = memberExists
                ? state.members.map((member: UserType) => member.id === action.payload.id ? {...action.payload} : member)
                : [...state.members, {...action.payload}];

            return {
                ...state,
                members,
                loading: false,
            };

        case 'ACCOUNT_MEMBER_DETACH':
            return {
                ...state,
                loading: true,
            };

        case 'ACCOUNT_MEMBER_DETACHED':
            return {
                ...state,
                members: state.members.filter((m: UserType) => m.id !== action.payload.id),
                loading: false,
            };

        case 'ACCOUNT_MEMBERS_REQUEST_FAILED':
            return {
                ...state,
                loading: false,
            };

        case 'ACCOUNT_MEMBER_INVITE_SENT':
            return {
                ...state,
                invitesResent: [...state.invitesResent, action.payload.id],
            };

        default:
            return state;
    }
};

export default AccountMembers;
