import {Cookies} from "react-cookie";
import {isUserAuthenticated} from "./authUtils";

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 * @param {*} refresh
 */
const fetchJSON = async (url, options = {}, refresh = false) => {

    // Prepend the base path
    const final_url = new URL('http' + (process.env.REACT_APP_USE_HTTPS==='1'?'s':'') + '://' +  process.env.REACT_APP_DOMAIN_NAME + process.env.REACT_APP_BASE_PATH + url);


    let cookies = new Cookies();
    const token = cookies.get('token');

    const headers = {};
    if (['POST', 'PUT'].indexOf(options.method) !== -1 ) {
        headers['Content-Type'] = 'application/json';
    }

    if (token) {
        headers['Authorization'] = 'Bearer '+ token;
    }

    options = {
        ...options,
        headers: new Headers(headers)
    };

    if (options.search !== undefined) {
        final_url.search = new URLSearchParams(options.search);
    }

    try {
        const response = await fetch(final_url, options);

        if (response.status === 401) {
            // avoid loop
            if (refresh === false && isUserAuthenticated() === true) {
                try {
                    const response_data = await response.json();

                    if (response_data.message === 'Token has expired') {
                        // Try to refresh token
                        const refresh_response = await fetchJSON('/login/refresh', {method: 'GET', headers: { 'Content-Type': 'application/json' }} , true);

                        let cookies = new Cookies();
                        cookies.set('token', refresh_response.token, { path: '/'});

                        return fetchJSON(url, options, true);

                    }
                } catch (e) {
                    console.warn(e);
                }
            }

            localStorage.removeItem('showTrialMessage');

            window.store.dispatch({ type: "APP_USER_NOT_CONNECTED", payload: {connected: false, user: null} });
            throw (await response.json());
        } else if (response.status !== 200) {
            throw await response.json();
        } else {
            const json_response = await response.json();

            if (json_response.success === false) {
                throw json_response;
            }

            return json_response.data;
        }

    } catch (e) {
        throw e;
    }
};

export { fetchJSON };
