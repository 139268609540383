// @flow
import {select, all, call, fork, put, takeEvery, cancel, delay} from 'redux-saga/effects';

import {
    linksLoaded,
    appRedirect,
    linksLoad,
    linkDeleted,
} from '../actions';

import {fetchJSON} from "../../helpers/api";

/**
 * Watch load link request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchLinksLoad(): any {
    yield takeEvery('LINKS_LOAD', function*({ payload: { domain_id } }) {
        try {
            const state = yield select();

            const options = {
                search: {
                    page: state.Links.pagination.page,
                    limit: state.Links.pagination.limit,
                },
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };


            if (state.Links.filters.languages) {
                options.search['languages'] = state.Links.filters.languages;
            }

            if (state.Links.filters.translation) {
                options.search['translation'] = state.Links.filters.translation;
            }

            if (state.Links.filters.original) {
                options.search['original'] = state.Links.filters.original;
            }

            const response = yield call(fetchJSON, '/domains/'+domain_id+'/links', options);

            yield put(linksLoaded(response.data, response.pagination));
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch user want to save a link
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchLinkDelete(): any {
    yield takeEvery('LINK_DELETE', function*({ payload: { domain_id, id } }) {
        try {
            const options = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            };

            yield call(fetchJSON, '/domains/'+domain_id+'/links/'+id, options);
            yield put(linkDeleted(domain_id, id));
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch link deleted
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchLinkDeleted(): any {
    yield takeEvery('LINK_DELETED', function*({payload: {domain_id}}) {
        yield put(linksLoad(domain_id));
    });
}

/**
 * Watch links filter change
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchLinksChangeInput(): any {
    // Wait the user to stop typing before loading links
    let taskDelayedLinksLoad = null;
    yield takeEvery('LINKS_CHANGE_INPUT', function* ({payload: {domain_id}}){
        if (taskDelayedLinksLoad !== null) {
            yield cancel(taskDelayedLinksLoad);
        }
        taskDelayedLinksLoad = yield fork(function* (){
            yield delay(500);
            yield put(linksLoad(domain_id));
        });
    });
}

function* LinksSaga(): any {
    yield all([fork(watchLinksLoad), fork(watchLinkDelete), fork(watchLinkDeleted), fork(watchLinksChangeInput)]);
}

export default LinksSaga;
