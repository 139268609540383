import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Validate current user access
 * @type: ReduxActionType
 */
export const userCan = (action: Function, ...args): ReduxActionType => ({
    type: 'POLICY_VALIDATE_USER_CAN',
    payload: {action, args},
});

/**
 * Validate current user access
 * @type: ReduxActionType
 */
export const userCannot = (action: Function, ...args): ReduxActionType => ({
    type: 'POLICY_VALIDATE_USER_CANNOT',
    payload: {action, args},
});

/**
 * Resolve entity binding from URL parameter
 * @type: ReduxActionType
 */
export const resolveStateBinding = (name: string, value: ?string): ReduxActionType => ({
    type: 'POLICY_RESOLVE_BINDING',
    payload: {name, value},
});
