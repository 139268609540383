import type {PolicyStateType} from "./index";
import type {AccountType} from "../flow/AccountType";

export const view = ({roles}: PolicyStateType, account: AccountType) => {
    return [roles.admin, roles.manager].includes(account.role);
};

export const manageMembers = ({roles}: PolicyStateType, account: AccountType) => {
    return [roles.admin, roles.manager].includes(account.role);
};

export const add = ({user}: PolicyStateType) => {
    return user.can_add_accounts;
};

export const manage = ({roles}: PolicyStateType, account: AccountType) => {
    return roles.admin === account.role;
};

export const remove = ({roles}: PolicyStateType, account: AccountType) => {
    return roles.admin === account.role;
};
