// @flow

import type {ReduxActionType} from "../../flow/ReduxActionType";
import type {DomainType} from "../../flow/DomainType";

/**
 * Load domain
 * @type: Action
 */
export const domainLoad = (id: number): ReduxActionType => ({
    type: 'DOMAIN_LOAD',
    payload: { id },
});

/**
 * Domain loaded
 * @type: Event
 */
export const domainLoaded = (domain: {}): ReduxActionType => ({
    type: 'DOMAIN_LOADED',
    payload: domain ,
});

/**
 * Change input content
 * @type: Action
 */
export const domainChangeInput = (name: string, value: all): ReduxActionType => ({
    type: 'DOMAIN_CHANGE_INPUT',
    payload: { name, value },
});

/**
 * Save a domain
 * @type: Action
 */
export const domainSave = (): ReduxActionType => ({
    type: 'DOMAIN_SAVE',
    payload: null,
});

/**
 * Domain has been saved
 * @type: Event
 */
export const domainSaved = (domain_id: number): ReduxActionType => ({
    type: 'DOMAIN_SAVED',
    payload: domain_id,
});

/**
 * Domain form has been close
 * @type: Event
 */
export const domainClosed = (): ReduxActionType => ({
    type: 'DOMAIN_CLOSED',
    payload: null,
});

/**
 * Delete a domain
 * @type: Action
 */
export const domainDelete = (domain: DomainType): ReduxActionType => ({
    type: 'DOMAIN_DELETE',
    payload: domain,
});

/**
 * Domain deleted
 * @type: Event
 */
export const domainDeleted = (domain_id: number): ReduxActionType => ({
    type: 'DOMAIN_DELETED',
    payload: domain_id,
});


/**
 * Reset a domain token
 * @type: Action
 */
export const domainTokenReset = (domain_id: number): ReduxActionType => ({
    type: 'DOMAIN_TOKEN_RESET',
    payload: domain_id,
});


/**
 * Request failed
 * @type: Event
 */
export const domainRequestFailed = (error): ReduxActionType => ({
    type: 'DOMAIN_REQUEST_FAILED',
    payload: error,
});

/**
 * Check DNS records
 * @type: Action
 */
export const domainCheckDNS = (domain_id: number): ReduxActionType => ({
    type: 'DOMAIN_CHECK_DNS',
    payload: domain_id,
});

/**
 * Check DNS records
 * @type: Action
 */
export const domainCheckedDNS = (result): ReduxActionType => ({
    type: 'DOMAIN_CHECKED_DNS',
    payload: result,
});

/**
 * Load language settings
 * @type: Action
 */
export const domainLanguageSettingsLoad = (id: number): ReduxActionType => ({
    type: 'DOMAIN_LANGUAGE_SETTINGS_LOAD',
    payload: {id},
});

/**
 * Language Settings loaded
 * @type: Action
 */
export const domainLanguageSettingsLoaded = (language_settings): ReduxActionType => ({
    type: 'DOMAIN_LANGUAGE_SETTINGS_LOADED',
    payload: language_settings,
});

/**
 * Save a language settings
 * @type: Action
 */
export const domainLanguageSettingsSave = (id: number, settings): ReduxActionType => ({
    type: 'DOMAIN_LANGUAGE_SETTINGS_SAVE',
    payload: {id, settings},
});

/**
 * Language Settings has been saved
 * @type: Action
 */
export const domainLanguageSettingsSaved = (language_settings): ReduxActionType => ({
    type: 'DOMAIN_LANGUAGE_SETTINGS_SAVED',
    payload: language_settings,
});


/**
 * Language Settings request failed
 * @type: Event
 */
export const domainLanguageSettingsRequestFailed = (): ReduxActionType => ({
    type: 'DOMAIN_LANGUAGE_SETTINGS_REQUEST_FAILED',
    payload: null,
});

/**
 * Language Settings clear data
 * @type: Event
 */
export const clearDomainLanguageSettingsData = (): ReduxActionType => ({
    type: 'DOMAIN_LANGUAGE_SETTINGS_CLEAR_DATA',
    payload: null,
});

export const domainSetForceSaving = (force_value): ReduxActionType => ({
    type: 'DOMAIN_SET_FORCE_SAVING',
    payload: force_value,
});