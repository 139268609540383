// @flow
import React, { Component } from 'react';
import Routes from './routes/Routes';
import '@stripe/stripe-js';
import tawkTo from "tawkto-react";

// Themes
import './assets/scss/DefaultTheme.scss';

type AppProps = {};

/**
 * Main app component
 */
class App extends Component<AppProps> {
    render() {
        return <Routes></Routes>;

    }

    /**
     * Check if GTM is enabled, if not launch manually tawkto
     * @param timeout
     */
    checkGA(timeout = false) {
        if (window.google_tag_manager !== undefined) {
            return;
        } else {
            if (timeout) {
                tawkTo(process.env.REACT_APP_TAWKTO_PROPERTY_ID, process.env.REACT_APP_TAWKTO_KEY);
            } else {
                setTimeout(() => this.checkGA(true),4000);
            }
        }
    }

    attachEntri() {
        console.log('attaching entri');
        const entriJs = document.getElementById('entri-js');
        // Attach Entri JS if not already attached
        if (!entriJs && process.env.REACT_APP_ENTRI_AUTO_DNS === '1') {
            const script = document.createElement('script');
            script.src = 'https://cdn.goentri.com/entri.js';
            script.async = true;
            script.type = 'text/javascript';
            script.id = 'entri-js';
            document.body.appendChild(script);
        }
    }

    attachPaypal() {
        const paypalJs = document.getElementById('paypal-js');

        const { REACT_APP_PAYPAL_CLIENT_ID, REACT_APP_CURRENCY } = process.env;

        console.log('attaching paypal');

        if (!paypalJs && REACT_APP_PAYPAL_CLIENT_ID && REACT_APP_CURRENCY) {
            const script = document.createElement('script');
            script.src = `https://www.paypal.com/sdk/js?client-id=${REACT_APP_PAYPAL_CLIENT_ID}&currency=${REACT_APP_CURRENCY}&components=buttons`;
            script.async = true;
            script.type = 'text/javascript';
            script.id = 'paypal-js';
            script.dataset.sdkIntegrationSource = 'linguise';
            document.body.appendChild(script);
        }
    }

    componentDidMount() {
        this.checkGA(false);
        this.attachEntri();
        this.attachPaypal();
    }
}

export default App;
