import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';

import TagManager from 'react-gtm-module';
import ScriptTag from "react-script-tag";

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <>
        <ScriptTag type="text/javascript" dangerouslySetInnerHTML={{__html:`
                    (function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
                    fpr("init", {cid:"${process.env.REACT_APP_FIRST_PROMOTER_ACCOUNT_ID}"});
                    fpr("click");
                `}} async />
        <ScriptTag type="text/javascript" src="https://cdn.firstpromoter.com/fpr.js" async />
        <Provider store={configureStore()}>
            <App />
        </Provider>
    </>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
