
// handle auth and authorization

import React, {Component} from "react";
import {appLoaded, appLoad, resolveStateBinding, userCannot, appRedirect} from '../redux/actions';
import {connect} from "react-redux";
import { Route, Redirect } from 'react-router-dom';
import Loading from "../pages/Loading";
import BaseRedirect from "../pages/BaseRedirect";
import { allFlattenRoutes as routes } from './index';
import type {RouteType} from "../flow/RouteType";

class PrivateRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            path: null,
        };
    }

    UNSAFE_componentWillMount(): void {
        // Avoid calling it twice
        if (this.props.app.loading === true) {
            // Load the async functions needed for the global app
            let domain_id = null;
            if (this.props.path && this.props.path.startsWith('/domain/:domain_id')) {
                // In case a domain is selected in the url we need to load it as we use it in the route
                domain_id = this.props.computedMatch.params.domain_id;
            }
            this.props.appLoad(domain_id);
        }
    }

    componentDidUpdate() {
        const {app: {loading}, path, computedMatch: {params: urlParams}} = this.props;
        const {resolveStateBinding, userCannot, appRedirect, appLoad} = this.props;

        if (!loading && this.state.path !== path) {
            this.setState({path});

            const currentRoute: ?RouteType = routes.find((r: RouteType) => r.path === path);

            if (currentRoute && currentRoute.policy) {
                const [firstUrlParamName] = Object.keys(urlParams);
                const firstUrlParamValue = firstUrlParamName ? parseInt(urlParams[firstUrlParamName]) : null;
                const entity = firstUrlParamValue && resolveStateBinding(firstUrlParamName, firstUrlParamValue);

                if (userCannot(currentRoute.policy, entity)) {
                    console.error('Access denied due to route policy', {path});
                    appRedirect('/error-403');
                    appLoad();
                }
            }
        }
    }

    render()
    {
        const {component: Component, roles, ...rest } = this.props;

        return (<Route
            {...rest}
            render={props => {
                if (this.props.app.redirect !== null) {
                    return <BaseRedirect location={this.props.location} app={this.props.app} />;
                }

                if (this.props.app.loading) {
                    return <Loading />
                }
                if (!this.props.app.connected) {
                    // not logged in so redirect to login page with the return url
                    return <Redirect to={{ pathname: '/account/login', state: { from: this.props.location } }} />;
                }

                // Do not open Language settings page for WP and Joomla
                if (this.props.app.domain && this.props.app.domain.platform && ['wordpress', 'joomla'].includes(this.props.app.domain.platform) && this.props.path === '/domain/:domain_id/languages/settings') {
                    return <Redirect to={{ pathname: `/domain/${this.props.app.domain.id}/dashboard` }} />;
                }

                const loggedInUser = this.props.app.user;
                // check if route is restricted by role
                if (loggedInUser && this.props.roles && this.props.roles.indexOf(loggedInUser.role) === -1) {
                    // role not authorised so redirect to home page
                    return <Redirect to={{ pathname: '/' }} />;
                }

                // authorised so return component
                return <Component {...props} />;
            }}
        />)
    }
}

const mapStateToProps = state => {
    return {
        app: state.App
    };
};

export default connect(
    mapStateToProps,
    {appLoaded, appLoad, resolveStateBinding, userCannot, appRedirect}
)(PrivateRoute);
