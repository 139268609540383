// @flow

import update from 'immutability-helper';
import type {ReduxActionType} from "../../flow/ReduxActionType";

const INITIAL_STATE = {
    translation: {
        id: 0,
        original: '',
        language: '',
        translation: '',
        original_translation: '',
        revisions: [],
    },
    is_inline: false,
    saving: false,
    deleting: false,
    saved: false
};

type State = { type?: string };

const Translation = (state: State = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'TRANSLATION_LOAD':
            return {...INITIAL_STATE};

        case 'TRANSLATION_LOADED':
            let translation = action.payload;
            translation.original_translation = translation.translation;

            if (translation.revisions.length) {
                translation.translation = translation.revisions[0].translation;
            }

            // Check if the translation contains inline data
            let is_inline = false;
            if (/<a i=[0-9]+>.*?<\/a>/gs.test(translation.original_translation)) {
                is_inline = true;
            }

            return {...state, translation: translation, is_inline};

        case 'TRANSLATION_CHANGE_TYPE':
            return {...state, translation: {type: action.payload,}};

        case 'TRANSLATION_CLICK_INSERTION':
            return {...state,};

        case 'TRANSLATION_CHANGE_INPUT':
            return update(state, {translation: {[action.payload.name]: {$set: action.payload.value}}});

        case 'TRANSLATION_SAVE':
            return {...state, saved: false, saving: true};

        case 'TRANSLATION_SAVED':
            return update(state, {translation: {revisions: {$set: [{id: action.payload.id}]}}, saved: {$set: false}, saving: {$set: false} });

        case 'TRANSLATION_SAVED_AND_QUIT':
          return {...INITIAL_STATE, saved: true, saving: false};

        case 'TRANSLATION_DELETE':
            return {...state, deleting: true};

        case 'TRANSLATION_DELETED':
            return {...INITIAL_STATE, saved: true};

        case 'TRANSLATION_CLOSED':
            return {...INITIAL_STATE};

        case 'TRANSLATION_REQUEST_FAILED':
            return {...state, saving: false, deleting: false};

        default:
            return {...state};
    }
};

export default Translation;
