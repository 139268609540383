// @flow
import React, {Component} from 'react';

import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

import {appRedirected} from "../redux/actions";
import {parse} from "query-string";
import type {DomainType} from "../flow/DomainType";
import type {AccountType} from "../flow/AccountType";
import {route} from "../helpers/common";

type AppState = {
    domains: DomainType[],
    accounts: AccountType[],
};

class BaseRedirect extends Component {

    render() {
        const {redirect, query, domains, accounts}: AppState = this.props.app;

        const {search} = this.props.location || {};
        const {domain_subscribed, payment_status, from, account_id} = parse(search);
        const subscribedDomain: ?DomainType = domain_subscribed && domains.find((d: DomainType) => d.id === parseInt(domain_subscribed));
        if (subscribedDomain) {
            const accountSubscriptionsURL = route('account.subscriptions', {account_id: subscribedDomain.account_id});
            console.log(accountSubscriptionsURL);

            return <Redirect to={{pathname: accountSubscriptionsURL, search }}/>
        }

        if (from === 'paypal_attach') {
            const firstAccount = account_id || (accounts[0] ? accounts[0].id : undefined);
            if (firstAccount) {
                const accountPaymentRoute = route('account.payment_methods', {account_id: account_id});
                return <Redirect to={{pathname: accountPaymentRoute, search}} />
            }
        }

        if (redirect !== null) {
            return <Redirect to={{pathname: redirect, search: query }}/>;
        }

        const [firstAccount: AccountType] = accounts;
        const defaultRedirect = firstAccount
            ? route('account.subscriptions', {account_id: firstAccount.id})
            : '/domain/add';

        return <Redirect to={defaultRedirect}/>;
    }

    componentDidMount() {
        this.update.apply(this);
    }

    componentDidUpdate(): * {
        this.update.apply(this);
    }

    update(): * {
        const {appRedirected, app: {redirect}} = this.props;

        if (redirect !== null) {
            appRedirected();
        }
    }
}

const mapStateToProps = state => {
    return {
        app: state.App
    };
};

export default connect(
    mapStateToProps,
    {appRedirected}
)(BaseRedirect);
