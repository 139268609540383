import React, {useState} from "react";
import {Tooltip} from "reactstrap";

const TooltipItem = ({content, placement, target}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    return (
        <Tooltip placement={placement} isOpen={tooltipOpen} target={target} toggle={() => setTooltipOpen(!tooltipOpen)}>
            {content}
        </Tooltip>
    )
}

export default TooltipItem;