// @flow
import {allFlattenRoutes} from "../routes/index";
import {generatePath} from "react-router-dom";
import type {RouteType} from "../flow/RouteType";
import {stringify} from "query-string";

export const capitalize = (str: string) => typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : str;

export const formatBytes = (a, b) => {
    if (0 == a) return "0 Bytes";

    const c = 1024, d = b || 2, e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        f = Math.floor(Math.log(a) / Math.log(c));

    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f]
};

export const nFormatter = (num, digits) => {
    const si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "K" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "G" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export const route = (name, params, query) => {
    const url = generatePath(allFlattenRoutes.find((r: RouteType) => r.routeName === name).path, params);
    return query ? `${url}?${stringify(query)}` : url;
};

export const shortLangCode = (lang: string) => typeof lang === 'string' ? (lang.toLowerCase().split('-'))[0] : lang;
