import React from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import policies from "../policies";
import type {RouteType} from "../flow/RouteType";
import type {AccountType} from "../flow/AccountType";
import type {DomainType} from "../flow/DomainType";

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../pages/auth/ResetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Unsubscribe = React.lazy(() => import('../pages/auth/Unsubscribe'));
const Invite = React.lazy(() => import('../pages/auth/Invite'));

const Profile = React.lazy(() => import('../pages/Profile'));

const Invoices = React.lazy(() => import('../pages/Invoices'));

const BillingDetails = React.lazy(() => import('../pages/BillingDetails'));

const ManageSubscription = React.lazy(() => import('../pages/ManageSubscription'));

const Home = React.lazy(() => import('../pages/Home'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));

const Rules = React.lazy(() => import('../pages/Rules'));
const Rule = React.lazy(() => import('../pages/Rule'));

const Domain = React.lazy(() => import('../pages/Domain'));
const NewDomainPreconfig = React.lazy(() => import('../pages/NewDomainPreconfig'));
const NewDomainFinish = React.lazy(() => import('../pages/NewDomainFinish'));
const NewDomainSubdomainSetup = React.lazy(() => import('../pages/NewDomainSubdomainSetup'));

const Translations = React.lazy(() => import('../pages/Translations'));
const Translation = React.lazy(() => import('../pages/Translation'));

const SearchTranslations = React.lazy(() => import('../pages/SearchTranslations'));

const Urls = React.lazy(() => import('../pages/Urls'));
const Url = React.lazy(() => import('../pages/Url'));

const Statistics = React.lazy(() => import('../pages/Statistics'));
const BaseRedirect = React.lazy(() => import('../pages/BaseRedirect'));

const PageNotFound  = React.lazy(() => import('../pages/PageNotFound'));
const AccessDenied  = React.lazy(() => import('../pages/AccessDenied'));
const ServerError500  = React.lazy(() => import('../pages/ServerError'));

const FrontendEditor = React.lazy(() => import('../pages/FrontendEditor'));

const AccountPayments = React.lazy(() => import('../pages/AccountPayments'));
const AccountMembers = React.lazy(() => import('../pages/AccountMembers'));

const Links = React.lazy(() => import('../pages/Links'));
const Link = React.lazy(() => import('../pages/Link'));

const LanguageSettings = React.lazy(() => import('../pages/LanguageSettings'));

// Subscription Message Page
const SubscriptionMessage =  React.lazy(() =>import('../pages/SubscriptionMessage'));

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: BaseRedirect,
    route: PrivateRoute,
};

// Only for translation parsing purpose
const t = (key, text) => key;

// dashboards
const mainRoutes: RouteType[] = [
    {
        path: '/error-404',
        name: 'Error 404',
        routeName: 'error.404',
        component: PageNotFound,
        route: PrivateRoute,
    },
    {
        path: '/error-403',
        name: 'Error 403',
        routeName: 'error.403',
        component: AccessDenied,
        route: PrivateRoute,
    },
    {
        path: '/error-500',
        name: 'Error 500',
        component: ServerError500,
        route: PrivateRoute,
    },
    {
        path: '/subscription-success',
        name: 'Success Subscription',
        component: SubscriptionMessage,
        route: PrivateRoute,
    },
    {
        path: '/accounts/:account_id/subscriptions',
        name: 'route.domain_list',
        icon: 'mdi mdi-format-list-bulleted',
        routeName: 'account.subscriptions',
        component: Home,
        route: PrivateRoute,
        exact: true,
        showInNavbar: (paramName) => ['account_id', 'domain_id'].includes(paramName),
        linkParamsResolver: (entity: AccountType|DomainType) => ({account_id: entity.account_id || entity.id}),
    },
    {
        path: '/domain/add',
        name: 'Add domain',
        routeName: 'domain.add',
        component: Domain,
        route: PrivateRoute,
    },
    {
        path: '/domain/:domain_id/dashboard',
        name: 'route.dashboard',
        routeName: 'domain.dashboard', // todo change all
        icon: 'dripicons-meter',
        header: 'Domain',
        component: Dashboard,
        route: PrivateRoute,
        showInNavbar: true,
    },
    {
        path: '/domain/:domain_id/rules',
        name: 'route.rules',
        icon: 'dripicons-toggles',
        component: Rules,
        route: PrivateRoute,
        showInNavbar: true,
        policy: policies.domain.manage,
        children: [
            {
                path: '/domain/:domain_id/rules/add',
                name: 'Add rule',
                component: Rule,
                route: PrivateRoute,
                policy: policies.domain.manage,
            },
            {
                path: '/domain/:domain_id/rules/edit/:id',
                name: 'Edit rule',
                component: Rule,
                route: PrivateRoute,
                policy: policies.domain.manage,
            },
        ]
    },
    {
        path: '/domain/:domain_id/translations',
        name: 'route.translations',
        icon: 'mdi mdi-google-translate',
        component: Translations,
        route: PrivateRoute,
        showInNavbar: true,
        children: [
            {
                path: '/domain/:domain_id/translations',
                name: 'route.translations',
                icon: 'mdi mdi-google-translate',
                component: Translations,
                route: PrivateRoute,
                showInNavbar: true,
                children: [
                    {
                        path: '/domain/:domain_id/translations/edit/:id',
                        name: 'Edit translation',
                        component: Translation,
                        route: PrivateRoute,
                    },
                ]
            },
            {
                path: '/domain/:domain_id/search_translations',
                name: 'route.search_translations',
                icon: 'mdi mdi-feature-search-outline',
                component: SearchTranslations,
                route: PrivateRoute,
                showInNavbar: true,
            },
            {
                path: '/domain/:domain_id/urls',
                name: 'route.urls_translations',
                icon: 'mdi mdi-link',
                component: Urls,
                route: PrivateRoute,
                showInNavbar: true,
                children: [
                    {
                        path: '/domain/:domain_id/urls/edit/:id',
                        name: 'Edit url translation',
                        component: Url,
                        route: PrivateRoute,
                    },
                ]
            },
            {
                path: '/domain/:domain_id/links',
                name: 'route.links',
                icon: 'mdi mdi-image',
                component: Links,
                route: PrivateRoute,
                showInNavbar: true,
                children: [
                    {
                        path: '/domain/:domain_id/links/add',
                        name: 'Add Media or External Link',
                        component: Link,
                        route: PrivateRoute,
                    },
                    {
                        path: '/domain/:domain_id/links/edit/:id',
                        name: 'Edit Media or External Link',
                        component: Link,
                        route: PrivateRoute,
                    },
                ]
            },
        ]
    },
    {
        path: '/domain/:domain_id/statistics',
        name: 'Statistics',
        icon: 'mdi mdi-google-analytics',
        component: Statistics,
        route: PrivateRoute,
    },
    {
        path: '/domain/:domain_id/editor',
        name: 'route.online_editor',
        icon: 'mdi mdi-auto-fix',
        component: FrontendEditor,
        route: PrivateRoute,
        showInNavbar: true,
    },
    {
        path: '/domain/:domain_id/settings',
        name: 'route.settings',
        routeName: 'domain.settings',
        icon: 'mdi mdi-settings-outline',
        component: Domain,
        route: PrivateRoute,
        showInNavbar: true,
        policy: policies.domain.manage,
        children: [
            {
                path: '/domain/:domain_id/settings',
                name: 'route.settings',
                routeName: 'domain.settings',
                icon: 'mdi mdi-settings-outline',
                component: Domain,
                route: PrivateRoute,
                showInNavbar: true,
                policy: policies.domain.manage,
            },
            {
                path: '/domain/:domain_id/languages/settings',
                name: 'route.language_display',
                routeName: 'domain.settings',
                icon: 'mdi mdi-flag-outline',
                component: LanguageSettings,
                route: PrivateRoute,
                showInNavbar: true,
                policy: policies.domain.manage,
            }
        ]
    },
    {
        path: '/domain/:domain_id/preconfig',
        name: 'route.domain.preconfig',
        component: NewDomainPreconfig,
        route: PrivateRoute,
    },
    {
        path: '/domain/:domain_id/finish',
        name: 'route.domain.finish',
        routeName: 'domain.wizard.finish',
        component: NewDomainFinish,
        route: PrivateRoute,
    },
    {
        path: '/domain/:domain_id/subdomain_setup',
        name: 'route.domain.subdomain_setup',
        routeName: 'domain.wizard.subdomain_setup',
        component: NewDomainSubdomainSetup,
        route: PrivateRoute,
    },
    {
        path: '/accounts/:account_id/subscriptions/:domain_id/:plan_id?',
        name: 'profile.manageSubscription',
        routeName: 'account.subscriptions.manage',
        component: ManageSubscription,
        route: PrivateRoute,
        exact: true,
        policy: policies.account.manage,
    },
    {
        path: '/accounts/:account_id/members',
        name: 'route.members',
        icon: 'mdi mdi-account-multiple',
        routeName: 'members',
        component: AccountMembers,
        route: PrivateRoute,
        exact: true,
        showInNavbar: true,
        policy: policies.account.manageMembers,
    },
    {
        path: '/accounts/:account_id/invoices',
        name: 'route.invoices',
        icon: 'mdi mdi-file-document',
        routeName: 'account.invoices',
        component: Invoices,
        route: PrivateRoute,
        exact: true,
        showInNavbar: true,
        policy: policies.account.manage,
    },
    {
        path: '/accounts/:account_id/billing',
        name: 'route.billing',
        icon: 'mdi mdi-deskphone',
        routeName: 'account.invoices',
        component: BillingDetails,
        route: PrivateRoute,
        exact: true,
        showInNavbar: true,
        policy: policies.account.manage,
    },
    {
        path: '/accounts/:account_id/payments',
        name: 'route.payment_methods',
        icon: 'mdi mdi-credit-card',
        routeName: 'account.payment_methods',
        component: AccountPayments,
        route: PrivateRoute,
        exact: true,
        showInNavbar: true,
        policy: policies.account.manage,
    },
    {
        path: '/profile',
        name: 'profile.account',
        routeName: 'profile',
        component: Profile,
        route: PrivateRoute,
    },
];

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            routeName: 'logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/account/password-reset',
            name: 'Password reset',
            routeName: 'password.reset',
            component: ResetPassword,
            route: Route,
        },
        {
            path: '/account/verify-email',
            name: 'Email verification',
            component: ResetPassword,
            route: Route,
        },
        {
            path: '/unsubscribe',
            name: 'Unsubscribe',
            component: Unsubscribe,
            route: Route,
        },
        {
            path: '/invite',
            name: 'Invite',
            component: Invite,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    authRoutes,
    ...mainRoutes
];

const authProtectedRoutes = mainRoutes;

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };

/**
 * Workaround for i18next to add find some text values

 t('route.dashboard', 'Dashboard')
 t('route.rules', 'Rules')
 t('route.translations', 'Translations')
 t('route.urls_translations', 'URLs translations')
 t('route.search_translations', 'Search Queries')
 t('route.online_editor', 'Live Editor')
 t('route.payment_methods', 'Payment methods')
 t('route.settings', 'Settings')
 t('route.domain.finish', 'Settings')
 t('route.domain.preconfig', 'Settings')
 t('route.domain.finish', 'Settings')
 */
