// @flow
import { select, all, call, fork, put, takeEvery, take } from 'redux-saga/effects';
import i18n from '../../i18n'

import {
    accountMembersLoaded,
    accountMemberSaved,
    accountMemberDetached,
    accountMembersRequestFailed,
    accountMemberInviteSent,
    appMessageThrow,
    appRedirect,
} from '../actions';
import {fetchJSON} from "../../helpers/api";

function* handle(error) {
    switch (error.status_code) {
        case 404:
            yield put(appRedirect('/error-404'));
            break;

        case 403:
        case 422:
            yield put(appMessageThrow(error.message, 'warning'));
            yield put(accountMembersRequestFailed());
            break;

        case 503: // Service Unavailable
            yield put(appMessageThrow(error.message, 'warning'));
            yield put(accountMembersRequestFailed());
            break;

        default:
            yield put(appRedirect('/error-500'));
            break;
    }
}

/**
 * Watch load account members request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAccountMembersLoad(): any {
    yield takeEvery('ACCOUNT_MEMBERS_LOAD', function*({payload: account_id}) {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, `/accounts/${account_id}/members`, options);
            yield put(accountMembersLoaded(response));
        } catch (error) {
            yield handle(error);
        }
    });
}

/**
 * Watch user want to save an account member
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAccountMemberSave(): any {
    yield takeEvery('ACCOUNT_MEMBER_SAVE', function*({payload: {account_id, member}}) {
        try {
            const options = {
                body: JSON.stringify(member),
                method: (member.id ? 'PUT' : 'POST'),
                headers: { 'Content-Type': 'application/json' },
            };

            const url = member.id
                ? `/accounts/${account_id}/members/${member.id}`
                : `/accounts/${account_id}/members`;

            const response = yield call(fetchJSON, url, options);

            yield put(accountMemberSaved(response));
            if (!member.id) {
                yield put(appMessageThrow(i18n.t('members.inviteSent'), 'success'));
            }
        } catch (error) {
            yield handle(error);
        }
    });
}

/**
 * Watch user want to detach an account member
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAccountMemberDetach(): any {
    yield takeEvery('ACCOUNT_MEMBER_DETACH', function*({payload: {account_id, member}}) {
        try {
            const options = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            };

            const url = `/accounts/${account_id}/members/${member.id}`;

            const response = yield call(fetchJSON, url, options);

            yield put(accountMemberDetached({id: member.id}));
        } catch (error) {
            yield handle(error);
        }
    });
}

/**
 * Watch user want to resend invite to account member
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchAccountMemberInviteResend(): any {
    yield takeEvery('ACCOUNT_MEMBER_INVITE_RESEND', function*({payload: {account_id, member}}) {
        try {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };

            const url = `/accounts/${account_id}/members/${member.id}/invite`;

            const response = yield call(fetchJSON, url, options);

            yield put(accountMemberInviteSent(member.id));
            yield put(appMessageThrow(i18n.t('members.inviteSent'), 'success'));
        } catch (error) {
            yield handle(error);
        }
    });
}

function* AccountMembersSaga(): any {
    yield all([fork(watchAccountMembersLoad), fork(watchAccountMemberSave), fork(watchAccountMemberDetach), fork(watchAccountMemberInviteResend)]);
}

export default AccountMembersSaga;
