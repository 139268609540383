// @flow


import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load links
 */
export const linksLoad = (domain_id): ReduxActionType => ({
    type: 'LINKS_LOAD',
    payload: {domain_id},
});

/**
 * Links loaded
 */
export const linksLoaded = (links: [], pagination: {}): ReduxActionType => ({
    type: 'LINKS_LOADED',
    payload: {links, pagination},
});

/**
 * Delete a link
 * @type: Action
 */
export const linkDelete = (domain_id: number, id: number): ReduxActionType => ({
    type: 'LINK_DELETE',
    payload: { domain_id, id },
});

/**
 * Link deleted
 * @type: Event
 */
export const linkDeleted = (domain_id: number, id: number): ReduxActionType => ({
    type: 'LINK_DELETED',
    payload: { domain_id, id },
});

/**
 * Change input content
 * @type: Action
 */
export const linksChangeInput = (name: string, value: all, type: string, domain_id: number): ReduxActionType => ({
    type: 'LINKS_CHANGE_INPUT',
    payload: { name, value, domain_id },
});
