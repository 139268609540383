// @flow
import { select, all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    ruleSaved,
    ruleSavedAndQuit,
    ruleClosed,
    ruleLoaded,
    ruleDeleted,
    appRedirect,
    appMessageThrow, ruleRequestFailed
} from '../actions';
import {fetchJSON} from "../../helpers/api";

/**
 * Watch load rule request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchRuleLoad(): any {
    yield takeEvery('RULE_LOAD', function*({ payload: { domain_id, id } }) {
        // Case new rule
        if (!id) {
            return yield put(ruleLoaded(null));
        }

        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, '/domains/'+domain_id+'/rules/'+id, options);
            yield put(ruleLoaded(response));
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch user want to save a rule
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchRuleSave(): any {
    yield takeEvery('RULE_SAVE', function*({ payload: { domain_id, quit } }) {
        try {
            const state = yield select();

            let rule = JSON.parse(JSON.stringify(state.Rule.rule));

            const options = {
                body: JSON.stringify(rule),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };

            let url = '/domains/'+domain_id+'/rules';
            if (state.Rule.rule.id !== 0) {
                options.method = 'PUT';
                url+= '/'+state.Rule.rule.id;
            }

            const response = yield call(fetchJSON, url, options);
            if (quit) {
              yield put(ruleSavedAndQuit(response));
              yield put(ruleClosed());
            } else {
              yield put(ruleLoaded(response))
              yield put(ruleSaved(response))
            }

        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else if (error.status_code === 422) {
                    yield put(appMessageThrow(error.message, 'warning'));
                    yield put(ruleRequestFailed());
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch user want to save a rule
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchRuleDelete(): any {
    yield takeEvery('RULE_DELETE', function*({ payload: { domain_id, id } }) {
        try {
            const options = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            };

            yield call(fetchJSON, '/domains/'+domain_id+'/rules/'+id, options);
            yield put(ruleDeleted(domain_id, id));
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

function* RuleSaga(): any {
    yield all([fork(watchRuleSave), fork(watchRuleLoad), fork(watchRuleDelete)]);
}

export default RuleSaga;
