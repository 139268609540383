// @flow
import { select, all, call, fork, put, takeEvery, cancel, delay } from 'redux-saga/effects';

import {
    searchTranslationsLoaded,
    searchTranslationsLoad,
    appRedirect
} from '../actions';

import {fetchJSON} from "../../helpers/api";

/**
 * Watch load translation request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchSearchTranslationsLoad(): any {
    yield takeEvery('SEARCH_TRANSLATIONS_LOAD', function*({ payload: { domain_id } }) {
        try {
            const state = yield select();

            const options = {
                search: {
                    page: state.SearchTranslations.pagination.page,
                    limit: state.SearchTranslations.pagination.limit,
                },
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            if (state.SearchTranslations.filters.languages) {
                options.search['languages'] = state.SearchTranslations.filters.languages;
            }

            if (state.SearchTranslations.filters.translation) {
                options.search['translation'] = state.SearchTranslations.filters.translation;
            }

            if (state.SearchTranslations.filters.original) {
                options.search['original'] = state.SearchTranslations.filters.original;
            }

            const response = yield call(fetchJSON, '/domains/'+domain_id+'/search_translations', options);

            yield put(searchTranslationsLoaded(response.data, response.pagination));
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch translations filter change
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchSearchTranslationsChangeInput(): any {
    // Wait the user to stop typing before loading translations
    let taskDelayedSearchTranslationsLoad = null;
    yield takeEvery('SEARCH_TRANSLATIONS_CHANGE_INPUT', function* ({payload: {domain_id}}){
        if (taskDelayedSearchTranslationsLoad !== null) {
            yield cancel(taskDelayedSearchTranslationsLoad);
        }
        taskDelayedSearchTranslationsLoad = yield fork(function* (){
            yield delay(500);
            yield put(searchTranslationsLoad(domain_id));
        });
    });
}

function* SearchTranslationsSaga(): any {
    yield all([fork(watchSearchTranslationsLoad), fork(watchSearchTranslationsChangeInput)]);
}

export default SearchTranslationsSaga;
