// @flow
import cookieToken from './cookieToken';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    return user !== false;
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const user = cookieToken.get();

    return user !== undefined;
};

export { isUserAuthenticated, getLoggedInUser };
