// @flow
import { select, all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    linkSaved,
    appRedirect,
    appMessageThrow,
    linkClosed,
    linkLoaded,
    linkRequestFailed,
    linksLoad,
    linkTranslationDeleted,
    linkSavedAndQuit,
} from '../actions';
import {fetchJSON} from "../../helpers/api";


/**
 * Watch user want to save a link
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchLinkSave(): any {
    yield takeEvery('LINK_SAVE', function*({ payload: { domain_id, quit } }) {
        try {
            const state = yield select();

            let link = JSON.parse(JSON.stringify(state.Link.link));

            const options = {
                body: JSON.stringify(link),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };

            let url = '/domains/'+domain_id+'/links';
            if (state.Link.link.id !== 0) {
                options.method = 'PUT';
                url+= '/'+state.Link.link.id;
            }

            const response = yield call(fetchJSON, url, options);
            if (quit) {
              yield put(linkSavedAndQuit(response));
              yield put(linkClosed());
            } else {
              yield put(linkSaved(response));
            }
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else if (error.status_code === 422) {
                yield put(appMessageThrow(error.message, 'warning'));
                yield put(linkRequestFailed());
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch load link request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchLinkLoad(): any {
    yield takeEvery('LINK_LOAD', function*({ payload: { domain_id, id } }) {
        // Case new link
        if (!id) {
            return yield put(linkLoaded(null));
        }

        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, '/domains/'+domain_id+'/links/'+id, options);
            yield put(linkLoaded(response));
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch delete link translation request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchLinkTranslationDelete(): any {
    yield takeEvery('LINK_TRANSLATION_DELETE', function*({ payload: { domain_id, id } }) {
        try {
            const options = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            };

            yield call(fetchJSON, '/domains/'+domain_id+'/link/translation/'+id, options);
            yield put(linkTranslationDeleted(domain_id, id));
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch link translation deleted
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchLinkTranslationDeleted(): any {
    yield takeEvery('LINK_TRANSLATION_DELETED', function*({payload: {domain_id}}) {
        yield put(linksLoad(domain_id));
    });
}

function* LinkSaga(): any {
    yield all([fork(watchLinkSave), fork(watchLinkTranslationDelete), fork(watchLinkTranslationDeleted), fork(watchLinkLoad)]);
}

export default LinkSaga;
