// @flow
import type {ReduxActionType} from "../../flow/ReduxActionType";
import update from "immutability-helper";

const INITIAL_STATE = {
    links: [],
    pagination: {
        page: 0,
        limit: 20,
        total: 0
    },
    filters: {
        languages: [],
        original: '',
        translation: ''
    },
    loading: true
};

type State = { type?: string };

const Links = (state: State = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'LINKS_LOAD':
            return {...state, loading: true};

        case 'LINKS_LOADED':
            return {...state, loading: false, links: action.payload.links, pagination: action.payload.pagination};

        case 'LINKS_CHANGE_INPUT':
            const result = /(.*)\.(.*)?/.exec(action.payload.name);
            if (result) {
                const new_state = update(state, {
                    [result[1]]: {
                        [result[2]]: {
                            $set: action.payload.value
                        }
                    }
                });
                return {...new_state};
            }
            return update(state, {filters: {[action.payload.name]: {$set: action.payload.value}}});

        default:
            return {...state};
    }
};

export default Links;
