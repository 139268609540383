// @flow

import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load rule
 * @type: Action
 */
export const ruleLoad = (domain_id: number, id: number): ReduxActionType => ({
    type: 'RULE_LOAD',
    payload: { domain_id, id },
});

/**
 * Rule loaded
 * @type: Event
 */
export const ruleLoaded = (rule: {}): ReduxActionType => ({
    type: 'RULE_LOADED',
    payload: rule ,
});

/**
 * Change rule type
 * @type: Action
 */
export const ruleChangeType = (type: string): ReduxActionType => ({
    type: 'RULE_CHANGE_TYPE',
    payload: { type },
});

/**
 * Change input content
 * @type: Action
 */
export const ruleChangeInput = (name: string, value: all, type: string): ReduxActionType => ({
    type: 'RULE_CHANGE_INPUT',
    payload: { name, value, type },
});

/**
 * Add a rule sub item (like ignore, css selector...)
 * @type: Action
 */
export const ruleSubAdd = (type: string, index: number | void): ReduxActionType => ({
    type: 'RULE_SUB_ADD',
    payload: { type , index },
});

/**
 * Add a rule sub item (like ignore, css selector...)
 * @type: Action
 */
export const ruleSubDelete = (type: string, index: number | void): ReduxActionType => ({
    type: 'RULE_SUB_DELETE',
    payload: { type , index },
});

/**
 * Save a rule
 * @type: Action
 */
export const ruleSave = (domain_id: number, quit: boolean): ReduxActionType => ({
    type: 'RULE_SAVE',
    payload: {domain_id, quit},
});

/**
 * Rule has been saved
 * @type: Event
 */
export const ruleSaved = (rule_id: number): ReduxActionType => ({
    type: 'RULE_SAVED',
    payload: rule_id,
});

/**
 * Rule has been saved and quit page
 * @type: Event
 */
export const ruleSavedAndQuit = (rule_id: number): ReduxActionType => ({
  type: 'RULE_SAVED_AND_QUIT',
  payload: rule_id,
});

/**
 * Rule form has been close
 * @type: Event
 */
export const ruleClosed = (): ReduxActionType => ({
    type: 'RULE_CLOSED',
    payload: null,
});

/**
 * Delete a rule
 * @type: Action
 */
export const ruleDelete = (domain_id: number, id: number): ReduxActionType => ({
    type: 'RULE_DELETE',
    payload: { domain_id, id },
});

/**
 * Rule deleted
 * @type: Event
 */
export const ruleDeleted = (domain_id: number, id: number): ReduxActionType => ({
    type: 'RULE_DELETED',
    payload: { domain_id, id },
});

/**
 * Rule request failed
 * @type: Event
 */
export const ruleRequestFailed = (): ReduxActionType => ({
    type: 'RULE_REQUEST_FAILED',
    payload: null,
});
