// @flow


import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load rules
 */
export const rulesLoad = (domain_id): ReduxActionType => ({
    type: 'RULES_LOAD',
    payload: {domain_id},
});

/**
 * Rules loaded
 */
export const rulesLoaded = (rules: [], pagination: {}): ReduxActionType => ({
    type: 'RULES_LOADED',
    payload: {rules, pagination},
});

/**
 * Change input content
 * @type: Action
 */
export const rulesChangeInput = (name: string, value: all, type: string, domain_id: number): ReduxActionType => ({
    type: 'RULES_CHANGE_INPUT',
    payload: { domain_id, name, value },
});
