// @flow

import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load url
 * @type: Action
 */
export const urlLoad = (domain_id: number, id: number): ReduxActionType => ({
    type: 'URL_LOAD',
    payload: { domain_id, id },
});

/**
 * Url loaded
 * @type: Event
 */
export const urlLoaded = (url: {}): ReduxActionType => ({
    type: 'URL_LOADED',
    payload: url ,
});

/**
 * Change input content
 * @type: Action
 */
export const urlChangeInput = (name: string, value: all, type: string): ReduxActionType => ({
    type: 'URL_CHANGE_INPUT',
    payload: { name, value, type },
});

/**
 * Click on insertion
 * @type: Action
 */
export const urlClickInsertion = (type: string, id: number): ReduxActionType => ({
    type: 'URL_CLICK_INSERTION',
    payload: { type, id },
});

/**
 * Save a url
 * @type: Action
 */
export const urlSave = (domain_id: number, quit: boolean): ReduxActionType => ({
    type: 'URL_SAVE',
    payload: {domain_id, quit},
});

/**
 * Url has been saved
 * @type: Event
 */
export const urlSaved = (url_id: number): ReduxActionType => ({
    type: 'URL_SAVED',
    payload: url_id,
});

/**
 * Url has been saved and quit
 * @type: Event
 */
export const urlSavedAndQuit = (url_id: number): ReduxActionType => ({
  type: 'URL_SAVED_AND_QUIT',
  payload: url_id,
});

/**
 * Url form has been close
 * @type: Event
 */
export const urlClosed = (): ReduxActionType => ({
    type: 'URL_CLOSED',
    payload: null,
});

/**
 * Delete a url
 * @type: Action
 */
export const urlDelete = (domain_id: number, id: number): ReduxActionType => ({
    type: 'URL_DELETE',
    payload: {domain_id, id},
});

/**
 * Url deleted
 * @type: Event
 */
export const urlDeleted = (url_id: number): ReduxActionType => ({
    type: 'URL_DELETED',
    payload: url_id,
});

/**
 * Request failed
 * @type: Event
 */
export const urlRequestFailed = (): ReduxActionType => ({
    type: 'URL_REQUEST_FAILED',
    payload: null,
});
