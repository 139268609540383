// @flow

import update from 'immutability-helper';
import type {ReduxActionType} from "../../flow/ReduxActionType";

const INITIAL_STATE = {
    url: {
        id: 0,
        original: '',
        language: '',
        translation: '',
        original_translation: '',
        revisions: []
    },
    saving: false,
    deleting: false,
    saved: false
};

type State = { type?: string };

const Url = (state: State = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'URL_LOADED':
            let url = action.payload;
            url.original_translation = url.translation;

            if (url.revisions.length) {
                url.translation = url.revisions[0].translation;
            }

            return {...state, url: url};

        case 'URL_CHANGE_TYPE':
            return {...state, url: {type: action.payload,}};


        case 'URL_CLICK_INSERTION':
            return {...state,};

        case 'URL_CHANGE_INPUT':
            return update(state, {url: {[action.payload.name]: {$set: action.payload.value}}});

        case 'URL_SAVE':
            return {...state, saving: true};

        case 'URL_SAVED':
          return update(state, {url: {revisions: {$set: [{id: action.payload.id}]}}, saved: {$set: false}, saving: {$set: false} });

        case 'URL_SAVED_AND_QUIT':
          return {...INITIAL_STATE, saved: true, saving: false};

        case 'URL_DELETE':
            return {...state, deleting: true};

        case 'URL_DELETED':
            return {...INITIAL_STATE, saved: true};

        case 'URL_CLOSED':
            return {...INITIAL_STATE};

        case 'URL_REQUEST_FAILED':
            return {...state, saving: false, deleting: false};

        default:
            return {...state};
    }
};

export default Url;
