// @flow

import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load billing
 * @type: Action
 */
export const billingLoad = (account_id: number): ReduxActionType => ({
    type: 'BILLING_LOAD',
    payload: account_id,
});

/**
 * Billing loaded
 * @type: Event
 */
export const billingLoaded = (billing: {}): ReduxActionType => ({
    type: 'BILLING_LOADED',
    payload: billing,
});

/**
 * Change input content
 * @type: Action
 */
export const billingChangeInput = (name: string, value: all): ReduxActionType => ({
    type: 'BILLING_CHANGE_INPUT',
    payload: { name, value },
});

/**
 * Save a billing
 * @type: Action
 */
export const billingSave = (account_id: number): ReduxActionType => ({
    type: 'BILLING_SAVE',
    payload: account_id,
});

/**
 * Request failed
 * @type: Event
 */
export const billingRequestFailed = (): ReduxActionType => ({
    type: 'BILLING_REQUEST_FAILED',
    payload: null,
});
