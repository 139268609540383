import React from 'react';

import { Container, Row, Col, Card, CardBody } from 'reactstrap';

import Spinner from "../components/Spinner";

import logo from '../assets/images/logo-dark.png'

import {withTranslation} from 'react-i18next';

const Loading = (props) => (
    <React.Fragment>
        <div className="mt-5 mb-5">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={5}>
                        <Card>
                            <CardBody className="p-4 position-relative">
                                <div className="text-center m-auto">
                                    <img alt="logo" src={logo} height="40" />

                                    <p>{props.t('general.loading', 'Please wait will the application is loading')}</p>
                                    <Spinner className="text-primary m-2" size="lg" />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>
);

export default withTranslation()(Loading);
