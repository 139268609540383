// @flow
import { select, all, call, fork, put, takeEvery, take } from 'redux-saga/effects';

import {
    domainSaved,
    domainClosed,
    domainLoaded,
    domainDeleted,
    appDomainsLoad,
    appChangeDomain,
    appRedirect,
    appMessageThrow,
    domainRequestFailed,
    domainChangeInput,
    appLoad,
    domainCheckedDNS,
    domainLanguageSettingsLoaded,
    domainLanguageSettingsSaved,
    domainLanguageSettingsRequestFailed,
} from '../actions';
import {fetchJSON} from "../../helpers/api";
import {pick} from "react-bootstrap-typeahead/es/utils";
import type {DomainType} from "../../flow/DomainType";
import type {ReduxActionType} from "../../flow/ReduxActionType";
import {route} from "../../helpers/common";

/**
 * Watch load domain request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchDomainLoad(): any {
    yield takeEvery('DOMAIN_LOAD', function*({ payload: { id } }) {
        if (!id) {
            // We add a domain, reset the form
            return yield put(domainLoaded(null));
        }

        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, '/domains/'+id, options);
            yield put(domainLoaded(response));
        } catch (error) {
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else if (error.status_code === 422) {
                yield put(appMessageThrow(error.message, 'warning'));
                yield put(domainRequestFailed());
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch user want to save a domain
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchDomainSave(): any {
    yield takeEvery('DOMAIN_SAVE', function*() {
        try {
            const state = yield select();

            // Only send the language code
            let domain = JSON.parse(JSON.stringify(state.Domain.domain));

            const keysToPick = [
                'url',
                'language',
                'translate_urls',
                'dynamic_translations',
                'allowed_languages',
                'url_separator',
                'account_id',
                'platform',
            ];
            if (!state.Domain.domain.id) {
                keysToPick.push('default_rules');
            }

            const data = pick(domain, keysToPick);

            if (!state.Domain.domain.id) {
                data.force_saving = state.Domain.force_saving;
            }

            const options = {
                body: JSON.stringify(data),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };

            let new_domain = true;

            let url = '/domains';
            if (state.Domain.domain.id !== 0) {
                options.method = 'PUT';
                url+= '/' + state.Domain.domain.id;
                new_domain = false;
            }

            const response = yield call(fetchJSON, url, options);
            if (response.status_code === 422) {
                yield put(appMessageThrow(response.message, 'warning'));
                yield put(domainRequestFailed(response.message));
                window.scrollTo(0, 0);
                return;
            }

            //todo: handle success=false response

            if (new_domain) {
                // Addition: Use -1 to indicate new domain, used when it's not first time setup.
                // appLoad redirect to home when there's no domain provided.
                yield put(appLoad(-1));
                yield take('APP_LOADED');
                yield put(appChangeDomain(response.id));
            }

            yield put(domainSaved(response.id));
            yield put(domainClosed());

            if (new_domain) {
                const suffixRedirect = response.structure === 'subdomains' ? 'subdomain_setup' : 'finish';
                yield put(appRedirect(`/domain/${response.id}/${suffixRedirect}`));
            }

        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch user want to save a domain
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchDomainDelete(): any {
    yield takeEvery('DOMAIN_DELETE', function* (action: ReduxActionType) {
        const {id, account_id}: DomainType = action.payload;

        try {
            const options = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            };

            yield call(fetchJSON, `/domains/${id}`, options);

            yield put(appRedirect(route('account.subscriptions', {account_id})));
            yield put(appDomainsLoad());
            yield take('APP_DOMAINS_LOADED');
            yield put(domainDeleted());
            yield put(domainClosed());
        } catch (error) {
           console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch user want to save a domain
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchDomainTokenReset(): any {
    yield takeEvery('DOMAIN_TOKEN_RESET', function*({ payload: id }) {
        try {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };

            const result = yield call(fetchJSON, '/domains/'+id+'/token/reset', options);
            yield put(domainChangeInput('token', result.token));
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch user want to check DNS
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchDomainDNSCheck(): any {
    yield takeEvery('DOMAIN_CHECK_DNS', function*({ payload: id }) {
        try {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };

            const result = yield call(fetchJSON, '/domains/'+id+'/dns/check', options);

            yield put(domainCheckedDNS(result));
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch user want to save a domain
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchDomainLanguageSettings(): any {
    yield takeEvery('DOMAIN_LANGUAGE_SETTINGS_SAVE', function*({payload: {id, settings}}) {
        try {
            const options = {
                body: JSON.stringify(Object.fromEntries(Object.entries(settings).filter(([k, _]) => k != null))),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };

            const url = `/domains/${id}/language/settings`;

            const response = yield call(fetchJSON, url, options);

            yield put(domainLanguageSettingsSaved(response));
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else if (error.status_code === 422) {
                yield put(appMessageThrow(error.message, 'warning'));
                yield put(domainLanguageSettingsRequestFailed());
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch load domain request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchDomainLanguageSettingsLoad(): any {
    yield takeEvery('DOMAIN_LANGUAGE_SETTINGS_LOAD', function*({ payload: { id } }) {
        if (!id) {
            // We add a domain, reset the form
            return yield put(domainLanguageSettingsLoaded(null));
        }

        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, '/domains/'+id+'/language/settings', options);
            yield put(domainLanguageSettingsLoaded(response));
        } catch (error) {
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else if (error.status_code === 422) {
                yield put(appMessageThrow(error.message, 'warning'));
                yield put(domainRequestFailed());
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

function* DomainSaga(): any {
    yield all([fork(watchDomainSave), fork(watchDomainLoad), fork(watchDomainDelete), fork(watchDomainTokenReset), fork(watchDomainDNSCheck), fork(watchDomainLanguageSettings), fork(watchDomainLanguageSettingsLoad)]);
}

export default DomainSaga;
