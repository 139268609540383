// @flow


import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load translations
 */
export const translationsLoad = (domain_id): ReduxActionType => ({
    type: 'TRANSLATIONS_LOAD',
    payload: {domain_id},
});

/**
 * Translations loaded
 */
export const translationsLoaded = (translations: [], pagination: {}): ReduxActionType => ({
    type: 'TRANSLATIONS_LOADED',
    payload: {translations, pagination},
});

/**
 * Change input content
 * @type: Action
 */
export const translationsChangeInput = (name: string, value: all, type: string, domain_id: number): ReduxActionType => ({
    type: 'TRANSLATIONS_CHANGE_INPUT',
    payload: { name, value, domain_id },
});
