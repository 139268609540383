// @flow

import type {ReduxActionType} from "../../flow/ReduxActionType";

const INITIAL_STATE = {
    invoices: [],
    loading: false,
    loadingPay: [],
};

type State = { type?: string };

const Invoices = (state: State = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'INVOICES_LOAD':
            return {
                ...state,
                loading: true,
            };

        case 'INVOICES_LOADED':
            return {
                ...state,
                invoices: [...action.payload],
                loading: false,
            };

        case 'INVOICES_PAY_INIT': {
            return {
                ...state,
                loadingPay: [...state.loadingPay, action.payload.invoice_id],
            };
        }

        case 'INVOICES_PAY_STARTED': {
            const loadingPay = state.loadingPay.filter(id => id !== action.payload);

            return {
                ...state,
                loadingPay,
            };
        }

        default:
            return state;
    }
}

export default Invoices;
