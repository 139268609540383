const editorRedirect = function(response)
{
    const form = document.createElement('form');
    form.setAttribute('id', 'editor_redirect_from');
    form.setAttribute('method', 'post');
    form.setAttribute('target', '_blank');
    form.setAttribute('style', 'display: none;');
    form.setAttribute("action", response.url);
    form.innerHTML = '';

    for (const property in response) {
        if (property === 'url') {
            continue;
        }

        form.innerHTML += `<input type="hidden" name="${property}" value='${response[property]}' />`;
    }

    document.body.appendChild(form);
    form.submit();
    form.parentNode.removeChild(form);
}

export { editorRedirect }