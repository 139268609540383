// @flow
import { select, all, call, fork, put, takeEvery, cancel, delay  } from 'redux-saga/effects';

import {
    rulesLoaded,
    rulesLoad,
    appRedirect
} from '../actions';

import {fetchJSON} from "../../helpers/api";

/**
 * Watch load rule request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchRulesLoad(): any {
    yield takeEvery('RULES_LOAD', function*({ payload: { domain_id } }) {
        try {
            const state = yield select();

            const options = {
                search: {
                    page: state.Rules.pagination.page,
                    limit: state.Rules.pagination.limit
                },
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, '/domains/'+domain_id+'/rules', options);

            yield put(rulesLoaded(response.data, response.pagination));
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch rule deleted
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchRuleDeleted(): any {
    yield takeEvery('RULE_DELETED', function*({payload: {domain_id, id}}) {
        yield put(rulesLoad(domain_id));
    });
}

/**
 * Watch rules filter change
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchRulesChangeInput(): any {
    // Wait the user to stop typing before loading rules
    let taskDelayedRulesLoad = null;
    yield takeEvery('RULES_CHANGE_INPUT', function* ({ payload: { domain_id } }){
        if (taskDelayedRulesLoad !== null) {
            yield cancel(taskDelayedRulesLoad);
        }
        taskDelayedRulesLoad = yield fork(function* (){
            yield delay(500);
            yield put(rulesLoad(domain_id));
        });
    });
}

function* RulesSaga(): any {
    yield all([fork(watchRulesLoad), fork(watchRulesChangeInput),fork(watchRuleDeleted)]);
}

export default RulesSaga;
