import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import {route, shortLangCode} from "../helpers/common";
import {withTranslation} from 'react-i18next';

import PaypalBindPromoBanner from "../assets/images/paypal-bind-promo-ENG.png";
import PaypalBindPromoBannerFR from "../assets/images/paypal-bind-promo-FRA.png";
import PaypalBindPromoBannerDE from "../assets/images/paypal-bind-promo-DEU.png";
import PaypalBindPromoBannerIT from "../assets/images/paypal-bind-promo-ITA.png";
import PaypalBindPromoBannerES from "../assets/images/paypal-bind-promo-SPA.png";
import PaypalBindPromoBannerRU from "../assets/images/paypal-bind-promo-RUS.png";
import PaypalBindPromoBannerJA from "../assets/images/paypal-bind-promo-JPN.png";
import PaypalBindPromoBannerVN from "../assets/images/paypal-bind-promo-VIE.png";
import PaypalBindPromoBannerNL from "../assets/images/paypal-bind-promo-NLD.png";

const promoMappings = {
    'en': PaypalBindPromoBanner,
    'fr': PaypalBindPromoBannerFR,
    'de': PaypalBindPromoBannerDE,
    'it': PaypalBindPromoBannerIT,
    'es': PaypalBindPromoBannerES,
    'ru': PaypalBindPromoBannerRU,
    'ja': PaypalBindPromoBannerJA,
    'vn': PaypalBindPromoBannerVN,
    'nl': PaypalBindPromoBannerNL,
}

const PaypalBanner = ({accountId, t, i18n, persist, onDismiss}) => {
    const [hidden, setHidden] = useState(persist ? false : true);
    const promoImage = promoMappings[shortLangCode(i18n.language)] || promoMappings['en'];
    const promoAlt = t('payments_method.paypal_bind.promo_img_alt', 'Connect your account with PayPal');

    useEffect(() => {
        // Check local storage
        const hidden = localStorage.getItem('linguise.paypal-banner-hide');
        if (!hidden && !persist) {
            setHidden(false);
        } else {
            const maxExpired = Number.parseInt(hidden);
            if (maxExpired < Date.now() && !persist) {
                setHidden(false);
            } else {
                if (typeof onDismiss === 'function') {
                    onDismiss();
                }
            }
        }
    }, []);

    const onDismissLocal = () => {
        setHidden(true);
        // Hide for 30 days
        const maxExpired = Date.now() + 30 * 24 * 60 * 60 * 1000;
        localStorage.setItem('linguise.paypal-banner-hide', maxExpired.toString());
        if (typeof onDismiss === 'function') {
            onDismiss();
        }
    }

    if (hidden) {
        return <></>
    }

    return (
        <div className="paypal-banner-container">
            {!persist && (
                <div className="paypal-close-container" onClick={onDismiss}>
                    <i className="dripicons-cross noti-icon"></i>
                </div>
            )}
            <Link className="p-0 w-100" to={route('account.payment_methods', {account_id: accountId})}>
                <img src={promoImage} alt={promoAlt} title={promoAlt} className="w-100" style={{height: 'auto', borderRadius: '10px'}} />
            </Link>
        </div>
    )
}

export default withTranslation()(PaypalBanner)
