// @flow


import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load translations
 */
export const searchTranslationsLoad = (domain_id): ReduxActionType => ({
    type: 'SEARCH_TRANSLATIONS_LOAD',
    payload: {domain_id},
});

/**
 * Translations loaded
 */
export const searchTranslationsLoaded = (search_translations: [], pagination: {}): ReduxActionType => ({
    type: 'SEARCH_TRANSLATIONS_LOADED',
    payload: {search_translations, pagination},
});

/**
 * Change input content
 * @type: Action
 */
export const searchTranslationsChangeInput = (name: string, value: all, type: string, domain_id: number): ReduxActionType => ({
    type: 'SEARCH_TRANSLATIONS_CHANGE_INPUT',
    payload: { name, value, domain_id },
});
