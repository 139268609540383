// @flow

import type {ReduxActionType} from "../../flow/ReduxActionType";
import type {AccountType} from "../../flow/AccountType";

/**
 * Load account
 * @type: Action
 */
export const accountLoad = (): ReduxActionType => ({
    type: 'ACCOUNT_LOAD',
    payload: null,
});

/**
 * Account loaded
 * @type: Event
 */
export const accountLoaded = (account: {}): ReduxActionType => ({
    type: 'ACCOUNT_LOADED',
    payload: account,
});

/**
 * Change input content
 * @type: Action
 */
export const accountChangeInput = (name: string, value: all): ReduxActionType => ({
    type: 'ACCOUNT_CHANGE_INPUT',
    payload: { name, value },
});

/**
 * Save an account
 * @type: Action
 */
export const accountSave = (): ReduxActionType => ({
    type: 'ACCOUNT_SAVE',
    payload: null,
});

/**
 * Request failed
 * @type: Event
 */
export const accountRequestFailed = (): ReduxActionType => ({
    type: 'ACCOUNT_REQUEST_FAILED',
    payload: null,
});

// todo THIS IS ACTUAL ACCOUNT MANAGEMENT INSTEAD OF PROFILE MANAGEMENT ABOVE!
export const accountsLoad = (): ReduxActionType => ({
    type: 'ACCOUNTS_LOAD',
    payload: null,
});

export const accountsLoaded = (accounts: AccountType[]): ReduxActionType => ({
    type: 'ACCOUNTS_LOADED',
    payload: accounts,
});

export const accountAdd = (name): ReduxActionType => ({
    type: 'ACCOUNT_ADD',
    payload: name,
});

export const accountAdded = (account: AccountType): ReduxActionType => ({
    type: 'ACCOUNT_ADDED',
    payload: account,
});
