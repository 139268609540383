// @flow
import React from 'react';

type HelpLinkIconProps = {
  href: string,
  title: string
}

const HelpLinkIcon = (props: HelpLinkIconProps) => (
    <React.Fragment>
        {props.href && (
            <a className="ml-1" href={props.href} title={props.title} target={props.href === "#" ? "_self" : "_blank"}>
                <svg width="16" height="16" viewBox="0 0 8.467 8.467" xmlns="http://www.w3.org/2000/svg" id={props.id}>
                    <path
                        d="M8.467 4.233a4.233 4.233 0 0 1-4.234 4.234A4.233 4.233 0 0 1 0 4.233 4.233 4.233 0 0 1 4.233 0a4.233 4.233 0 0 1 4.234 4.233z"
                        fill="#6c757d"/>
                    <path
                        d="M3.35 6.193q.004-.302.207-.496.202-.195.525-.195.322 0 .52.195.199.194.207.496.008.302-.19.5-.21.194-.546.194-.33 0-.529-.194-.194-.198-.194-.5zm.727-1.054q-.24 0-.4-.195-.162-.198-.116-.438.107-.558.566-.885.459-.322.463-.785 0-.43-.604-.459-.347-.016-.566.22-.219.235-.463.098-.149-.09-.223-.306-.075-.214.029-.417.107-.199.285-.244.575-.149 1.15-.149.768 0 1.17.347.4.348.396.807 0 .458-.194.814-.194.356-.5.496-.41.194-.46.629-.02.194-.173.33-.149.137-.347.137z"
                        fill="#fff" strokeWidth=".265" aria-label="?"/>
                </svg>
            </a>
        )}
    </React.Fragment>
)
export default HelpLinkIcon;