// @flow

import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load invoices
 * @type: Action
 */
export const invoicesLoad = (account_id: number): ReduxActionType => ({
    type: 'INVOICES_LOAD',
    payload: account_id,
});

/**
 * Invoices loaded
 * @type: Event
 */
export const invoicesLoaded = (invoices: []): ReduxActionType => ({
    type: 'INVOICES_LOADED',
    payload: invoices,
});

/**
 * Load pending invoices
 * @type: Action
 */
export const invoicesPayInit = (invoice_id: number, account_id: number): ReduxActionType => ({
    type: 'INVOICES_PAY_INIT',
    payload: {
        invoice_id,
        account_id,
    },
});

/**
 * Pending invoices loaded
 * @type: Event
 */
export const invoicesPayStarted = (invoice_id: number): ReduxActionType => ({
    type: 'INVOICES_PAY_STARTED',
    payload: invoice_id,
});
