// @flow
import { select, all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    translationSaved,
    translationClosed,
    translationLoaded,
    translationDeleted,
    translationRequestFailed,
    translationSavedAndQuit,
    appRedirect,
    appMessageThrow
} from '../actions';
import {fetchJSON} from "../../helpers/api";

/**
 * Watch load translation request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchTranslationLoad(): any {
    yield takeEvery('TRANSLATION_LOAD', function*({ payload: { domain_id, id } }) {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, '/domains/'+domain_id+'/translations/'+id, options);
            yield put(translationLoaded(response));
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch user want to save a translation
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchTranslationSave(): any {
    yield takeEvery('TRANSLATION_SAVE', function*({ payload: { domain_id, quit } }) {
        try {
            const state = yield select();

            const translation = state.Translation.translation.translation;

            const options = {
                body: JSON.stringify({translation: translation}),
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };

            let url = '/domains/'+domain_id+'/translations/'+state.Translation.translation.id;
            if (state.Translation.translation.revisions.length !== 0) {
                options.method = 'PUT';
                url+= '/'+state.Translation.translation.revisions[0].id;
            }

            const response = yield call(fetchJSON, url, options);
            if (quit) {
              yield put(translationSavedAndQuit(response));
              yield put(translationClosed());
            } else {
              yield put(translationSaved(response));
            }
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else if (error.status_code === 422) {
                yield put(appMessageThrow(error.message, 'warning'));
                yield put(translationRequestFailed());
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch user want to save a translation
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchTranslationDelete(): any {
    yield takeEvery('TRANSLATION_DELETE', function*({ payload: { domain_id, id } }) {
        try {
            const state = yield select();

            const options = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            };

            let url = '/domains/'+domain_id+'/translations/'+state.Translation.translation.id + '/'+state.Translation.translation.revisions[0].id;

            yield call(fetchJSON, url, options);
            yield put(translationDeleted(id));
            yield put(translationClosed());
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

function* TranslationSaga(): any {
    yield all([fork(watchTranslationSave), fork(watchTranslationLoad), fork(watchTranslationDelete)]);
}

export default TranslationSaga;
