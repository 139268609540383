// @flow
import { select, all, call, fork, put, takeEvery, cancel, delay } from 'redux-saga/effects';

import {
    statisticsLoaded,
    statisticsLanguagesLoaded,
    statisticsVisitsLoaded,
    statisticsPagesLoaded,
    statisticsUsagesLoaded,
    statisticsLoad,
    appRedirect
} from '../actions';

import {fetchJSON} from "../../helpers/api";

/**
 * Watch load statistic request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchStatisticsLoad(): any {
    yield takeEvery('STATISTICS_LOAD', function*({ payload: { domain_id } }) {
        try {
            const state = yield select();

            const options = {
                search: {
                    page: state.Statistics.pagination.page,
                    limit: state.Statistics.pagination.limit
                },
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            if (state.Statistics.filters.languages) {
                options.search['languages'] = state.Statistics.filters.languages;
            }

            if (state.Statistics.filters.url) {
                options.search['url'] = state.Statistics.filters.url;
            }

            if (state.Statistics.filters.ip_address) {
                options.search['ip_address'] = state.Statistics.filters.ip_address;
            }

            const response = yield call(fetchJSON, '/domains/'+domain_id+'/statistics', options);

            yield put(statisticsLoaded(response.data, response.pagination));
        } catch (error) {
            console.warn(error);
            if (error.status_code === 404) {
                yield put(appRedirect('/error-404'));
            } else {
                yield put(appRedirect('/error-500'));
            }
        }
    });
}

/**
 * Watch load statistic charts (languages)
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchStatisticsLanguagesLoad(): any {
    yield takeEvery('STATISTICS_LANGUAGES_LOAD', function*({ payload: { domain_id } }) {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, `/domains/${domain_id}/statistics/languages`, options);

            yield put(statisticsLanguagesLoaded(response));
        } catch (error) {
            console.warn(error);
        }
    });
}

/**
 * Watch load statistic charts (visits)
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchStatisticsVisitsLoad(): any {
    yield takeEvery('STATISTICS_VISITS_LOAD', function*({ payload: { domain_id } }) {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, `/domains/${domain_id}/statistics/visits`, options);

            yield put(statisticsVisitsLoaded(response));
        } catch (error) {
            console.warn(error);
        }
    });
}

/**
 * Watch load statistic charts (pages)
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchStatisticsPagesLoad(): any {
    yield takeEvery('STATISTICS_PAGES_LOAD', function*({ payload: { domain_id } }) {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, `/domains/${domain_id}/statistics/pages`, options);

            yield put(statisticsPagesLoaded(response));
        } catch (error) {
            console.warn(error);
        }
    });
}

/**
 * Watch load statistic charts (usages)
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchStatisticsUsagesLoad(): any {
    yield takeEvery('STATISTICS_USAGES_LOAD', function*({ payload: { domain_id } }) {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = yield call(fetchJSON, `/domains/${domain_id}/statistics/usages`, options);

            yield put(statisticsUsagesLoaded(response));
        } catch (error) {
            console.warn(error);
        }
    });
}

/**
 * Watch statistics filter change
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchStatisticsChangeInput(): any {
    // Wait the user to stop typing before loading statistics
    let taskDelayedStatisticsLoad = null;
    yield takeEvery('STATISTICS_CHANGE_INPUT', function* ({payload: {domain_id}}){
        if (taskDelayedStatisticsLoad !== null) {
            yield cancel(taskDelayedStatisticsLoad);
        }
        taskDelayedStatisticsLoad = yield fork(function* (){
            yield delay(500);
            yield put(statisticsLoad(domain_id));
        });
    });
}

function* StatisticsSaga(): any {
    yield all([
        fork(watchStatisticsLoad),
        fork(watchStatisticsLanguagesLoad),
        fork(watchStatisticsVisitsLoad),
        fork(watchStatisticsPagesLoad),
        fork(watchStatisticsUsagesLoad),
        fork(watchStatisticsChangeInput)
    ]);
}

export default StatisticsSaga;
